export const WINDOWS_DOORS_API_URL =
    process.env.REACT_APP_BASE_API_URL; /* 'https://localhost:5001' */
export const FILE_STORAGE_URL = process.env.REACT_APP_FILE_STORAGE_URL;
export const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyBKDeDCyCAHKPru29FgkbODlVNwQGEzDPg';
export const WINDOWS_DOOR_OTHER_OPTION = 'Custom-Other'


export const WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX = '_other_value';
export const LOGIN_DEMO_KEY = 'demokey';

export enum StorageKey {
    CurrentUser = 'CURRENT_USER',
    AuthState = 'AUTH_STATE',
}
