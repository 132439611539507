import { ErrorMessage, useFormikContext } from 'formik';
import Autocomplete from 'react-google-autocomplete';
import { YOUR_GOOGLE_MAPS_API_KEY } from '../../constants';

interface DatePickerFieldProps {
    name: string;
    label: string;
    value: string;
}

export const AddressField = ({ name, label, value }: DatePickerFieldProps): any => {
    const { setFieldValue } = useFormikContext();
    const setAddress = (formattedAddress: string, latitude: number, longitude: number) => {
        setFieldValue(name, formattedAddress);
        setFieldValue('latitude', latitude);
        setFieldValue('longitude', longitude);
    };
    const searchOptions = {
        componentRestrictions : {country: ['us','pr']},
        types: ['address']
    };
    return (
        <div>
            {label && <label className="label">{label}</label>}
            <Autocomplete
                apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                defaultValue={value}
                onPlaceSelected={(place) =>
                    setAddress(
                        place?.formatted_address,
                        place.geometry.location.lat(),
                        place.geometry.location.lng(),
                    )
                } 
                options = {searchOptions}
            />
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
