import { chain, filter, isEmpty, join, map } from 'lodash';
import * as Yup from 'yup';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Building } from '../../../models/claim/building';
import { MasterType } from '../../../models/masters/master-type';
import { UnitDoor } from '../../../models/claim/unit-door';
import { EditDoorForm } from './edit-door.form';
import {
    WINDOWS_DOORS_API_URL,
    WINDOWS_DOOR_OTHER_OPTION,
    WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX,
} from '../../../constants';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { createAttributeObject } from '../../../utils/building.utils';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { TemplateModel } from '../../../models/template-model';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { MasterValue } from '../../../models/masters/master-value';

interface EditDoorProps {
    data?: { building: Building; editDoor: UnitDoor };
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditDoor = ({ data: { building, editDoor }, onClose }: EditDoorProps) => {
    const { isLoading, result, isError } = useLoadData<MasterType[]>(`masters?type=door`);

    const title = editDoor.doorID ? 'Edit DOOR' : 'ADD NEW DOOR';
    const [templates, setTemplates] = useState<TemplateModel[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateModel | null>(null);
    const [initialData, setInitialData] = useState<any>();



    useEffect(() => {
        if (result) {

            setInitialData({
                doorID: editDoor.doorID,
                doorName: editDoor.doorName,
                direction: editDoor.direction,
                doorNotes: editDoor.notes,
                hasDamage: editDoor.hasDamage,
                ...(result && { ...createAttributeObject(result, editDoor.attributes) }),
            });
            console.log(initialData);

        }


    }, [editDoor, result])
    useEffect(() => {
        if (building !== null && building) {
            axios.get(`${WINDOWS_DOORS_API_URL}/api/TemplateAsset/GetGetTemplateAssetsByEntityType/${building.buildingID}/door`)
                .then(response => {
                    setTemplates(response.data);

                    if (response.data) {
                        let templates: TemplateModel[] = response.data as TemplateModel[];
                        if (templates.length > 0) {
                            let defaultTemplate = templates.filter(e => e.templateDefaultID > 0);
                            if (!isEmpty(defaultTemplate)) {
                                setSelectedTemplate(defaultTemplate[0]);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error("Error loading templates:", error);
                });
        }
    }, [building?.buildingID]);

    useEffect(() => {
        // Update initialData based on selectedTemplate and result
        if (selectedTemplate && result && !editDoor.doorID) {
            const newInitialData = {
                observations: editDoor.observations,
                images: editDoor.images,
                // notes: selectedTemplate.notes,
                doorID: editDoor.doorID,
                roomID: editDoor.roomID,
                imageCordinateID: editDoor.imageCordinateID,
                doorName: editDoor.doorName,
                direction: selectedTemplate.direction ?? '',
                ...createAttributeObject(result, selectedTemplate.attributes.map(e => { return { attributeName: e.attributeName, attributeValue: e.attributeValue, attributeid: e.attributeId } })),
            };
            setInitialData(newInitialData);
        }
    }, [selectedTemplate, result]);
    const parseDoorData = (data: any) => {
        editDoor.doorName = data.doorName;
        editDoor.direction = data.direction;
        editDoor.notes = data.doorNotes;
        editDoor.hasDamage = data.hasDamage;
        if (editDoor.roomID === 0) {
            editDoor.roomID = Number(data.roomID);
        }
        editDoor.attributes = result.map((item) => {
            return { attributeName: item.name, attributeValue: data[item.name] };
        });
        editDoor.attributes = chain(result)
            .map((item) => {
                return {
                    attributeName: item.name,
                    attributeValue: item.inputType !== InputFieldType.MultiSelect ? getAttributeValue(item, data) : getMultiValue(item, data),

                };
            })
            .filter((a) => !!a.attributeValue)
            .unionBy((a) => a.attributeName)
            .value();
        return editDoor;
    };

    const getAttributeValue = (item: any, data: any) => {
        const value = data[item.name];
        return value &&
            item.inputType === InputFieldType.SingleSelect &&
            value.toLowerCase() === WINDOWS_DOOR_OTHER_OPTION.toLowerCase()
            ? data[item.name + WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX]
            : value;
    };



    const getMultiValue = (item: MasterType, data: any) => {
        let customOption = '';
        if (item.inputType === InputFieldType.MultiSelect) {
            const selectedItems = item.values.filter((v: MasterValue) => {
                if (v.value.toLowerCase() !== WINDOWS_DOOR_OTHER_OPTION.toLowerCase()) {
                    return data[v.value];
                }
                else {
                    if (data[`${v.value}-${v.id}`] === true && data[`${v.value}-${v.id}`] !== "" && data[`${v.value}value-${v.id}`] !== undefined && data[`${v.value}value-${v.id}`] !== '') {
                        customOption = data[`${v.value}value-${v.id}`];

                        return true;
                    }
                }
            }
            );
            console.log(selectedItems);
            let option = join(map(selectedItems, (s) => s.value), ',');
            if (customOption !== '') {
                option = option + ":" + customOption;
            }
            console.log(option);

            return option;
        }

        else if (item.inputType === InputFieldType.Text || item.inputType === InputFieldType.Date) {
            return data[item.name];
        }
        return null;
    };


    const handleTemplateChange = (e: DropDownListChangeEvent) => {

        setSelectedTemplate(e.target.value as TemplateModel || null);
    };


    const validationSchema = Yup.object().shape({
        doorName: Yup.string().required('Required'),
    });

    return (
        <>
            <FormikModelDialog
                width='70%'
                title={title}
                validate={() => { }}
                show={!!editDoor}
                initialData={initialData}
                validationSchema={validationSchema}
                postRoute="doors/door"
                postDataFormatter={parseDoorData}
                onClose={onClose}
            >
                <>

                    <LoadingIndicator isLoading={isLoading} />
                    {result && (<>
                        <EditDoorForm onTemplateChange={handleTemplateChange} selectedTemplate={selectedTemplate} templates={templates} attributes={result} building={building} door={editDoor} /></>

                    )}
                </>
            </FormikModelDialog>
        </>
    );
};
