import Select from 'react-select';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { ObservationListComponent } from './observation-list.component';
import { Building, } from '../../../models/claim/building';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { GetBuildingDetail } from '../../../redux/actions/buildingactions';
import { GetClaimObservationForEntity } from '../../../redux/actions/claimobservationaction';
import { Unit } from '../../../models/claim/unit';
import { Floor } from '../../../models/claim/floor';

interface CopyUnitObservationsComponentProps {
    onCopyObservation: (observation: ClaimObservation) => any;
    buildingList: Building[];
}

interface SelectOption {
    label: string;
    value: number;
}

export const CopyUnitObservationsComponent = ({
    onCopyObservation,
    buildingList,
}: CopyUnitObservationsComponentProps) => {
    const [building, setBuilding] = useState<SelectOption>(null);
    const [floors, setFloors] = useState<SelectOption[]>([]);
    const [units, setUnits] = useState<SelectOption[]>([]);
    const [floor, setFloor] = useState<SelectOption>(null);
    const [floorlist, setfloorlist] = useState<Floor[]>([]);
    const [floorObj, setFloorObj] = useState<Floor>(null);
    const [unit, setUnit] = useState<SelectOption>(null);
    const [observations, setObservations] = useState<ClaimObservation[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!isEmpty(buildingList)) {
            const buildingOptions: SelectOption[] = buildingList.map(b => ({
                label: b.buildingName,
                value: b.buildingID
            }));
            setBuilding(buildingOptions[0]);
        }
    }, [buildingList]);

    useEffect(() => {
        if (building) {
            dispatch(GetBuildingDetail(building.value)).then(value => {
                const data = value.payload as Building;
                const floorOptions: SelectOption[] = data.floors.map(f => ({
                    label: f.floorName,
                    value: f.floorID
                }));
                setFloors(floorOptions);
                setfloorlist(data.floors);
                setUnits([]);
                setFloor(null);
                setUnit(null);
            });
        }
    }, [building]);

    useEffect(() => {
        if (floor && floorObj) {
            const unitOptions: SelectOption[] = floorObj.units.map(u => ({
                label: u.unitName,
                value: u.unitID
            }));
            setUnits(unitOptions);
            setUnit(null);
        }
    }, [floor]);

    useEffect(() => {
        if (unit) {
            dispatch(GetClaimObservationForEntity({ entityID: unit.value, entityType: 'unit' })).then(value => {
                if (unit) {
                    dispatch(GetClaimObservationForEntity({ entityID: unit.value, entityType: 'unit' })).then(value => {
                        const data = value.payload as ClaimObservation[];
                        let modifiedData: ClaimObservation[] = JSON.parse(JSON.stringify(data));
                        setObservations(modifiedData);
                    });
                }
            });
        }
    }, [unit]);

    const handleBuildingSelectionChange = (selectedBuilding: SelectOption) => {
        const selectedBuildingData = buildingList.find(b => b.buildingID === selectedBuilding.value);
        setBuilding(selectedBuilding);
        if (selectedBuildingData) {
            const floorOptions: SelectOption[] = selectedBuildingData.floors.map(f => ({
                label: f.floorName,
                value: f.floorID
            }));
            setFloors(floorOptions);
            setUnits([]);
            setFloor(null);
            setUnit(null);
        }
    };

    const handleFloorSelectionChange = (selectedFloor: SelectOption) => {
        setFloor(selectedFloor);
        setFloorObj(floorlist.find(e => e.floorID === selectedFloor.value))
    };

    const handleUnitSelectionChange = (selectedUnit: SelectOption) => {
        setUnit(selectedUnit);
    };

    return (
        <div className='copyObservation'>
            <div>
                <label>Buildings</label>
                <Select
                    value={building}
                    options={buildingList.map(b => ({ label: b.buildingName, value: b.buildingID }))}
                    onChange={handleBuildingSelectionChange}
                />
            </div>
            <div>
                <label> Floors</label>
                <Select value={floor} options={floors} onChange={handleFloorSelectionChange} />
            </div>
            <div>
                <label>Units</label>
                <Select value={unit} options={units} onChange={handleUnitSelectionChange} />
            </div>
            <div className="copy-observation-list">
                <ObservationListComponent
                    observations={observations}
                    onCopyObservation={onCopyObservation}
                    ispopup
                    onShowObservationPhotos={() => { }}
                />
            </div>
        </div>
    );
};
