import Select from 'react-select';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { ObservationListComponent } from './observation-list.component';
import { Building } from '../../../models/claim/building';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { GetBuildingDetail } from '../../../redux/actions/buildingactions';
import { GetClaimObservationForEntity } from '../../../redux/actions/claimobservationaction';

interface CopyFloorObservationsComponentProps {
    onCopyObservation: (observation: ClaimObservation) => any;
    buildingList: Building[];
}

export const CopyFloorObservationsComponent = ({
    ...props
}: CopyFloorObservationsComponentProps) => {
    const { buildingList, onCopyObservation } = props;
    const [building, setBuilding] = useState<SelectOptions>(null);
    const [floors, setFloors] = useState<SelectOptions[]>([]);
    const [floor, setFloor] = useState<SelectOptions>(null);
    const [observations, setObservations] = useState<ClaimObservation[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!isEmpty(buildingList)) {
            const buildingOptions: SelectOptions[] = buildingList.map(b => ({
                label: b.buildingName,
                value: b.buildingID
            }));
            setBuilding(buildingOptions[0]);
        }
    }, [buildingList]);

    useEffect(() => {
        if (building) {
            dispatch(GetBuildingDetail(building.value)).then(value => {
                let modifiedData: Building = JSON.parse(JSON.stringify(value.payload as Building));
                const buildingFloors = modifiedData.floors?.map(b => ({
                    label: b.floorName,
                    value: b.floorID
                })) || [];
                setFloors(buildingFloors);
                if (buildingFloors.length > 0) {
                    setFloor(buildingFloors[0]);
                } else {
                    setFloor(null);
                }
            });
        }
    }, [building]);

    useEffect(() => {
        if (floor) {
            dispatch(GetClaimObservationForEntity({ entityID: floor.value, entityType: 'floor' })).then(value => {

                const data = value.payload as ClaimObservation[];
                let modifiedData: ClaimObservation[] = JSON.parse(JSON.stringify(data));
                setObservations(modifiedData);
            });
        }
    }, [floor]);

    const handleBuildingSelectionChange = (data: SelectOptions) => {
        setBuilding({ label: data.label, value: data.value });
    };

    const handleFloorSelectionChange = (data: SelectOptions) => {
        setFloor({ label: data.label, value: data.value });
        if (floor) {
            dispatch(GetClaimObservationForEntity({ entityID: floor.value, entityType: 'floor' })).then(value => {

                const data = value.payload as ClaimObservation[];
                let modifiedData: ClaimObservation[] = JSON.parse(JSON.stringify(data));
                setObservations(modifiedData);
            });
        }
    };

    return (
        <>
            <div className='copyObservation'>
                <label> Buildings</label>
                <Select
                    value={building}
                    options={buildingList.map(b => ({ label: b.buildingName, value: b.buildingID }))}
                    onChange={handleBuildingSelectionChange}
                />
                <label> Floors</label>
                <Select value={floor} options={floors} onChange={handleFloorSelectionChange} />
                <label> Observations</label>
                <div className="copy-observation-list">
                    <ObservationListComponent
                        observations={observations}
                        onCopyObservation={onCopyObservation}
                        ispopup
                        onShowObservationPhotos={() => { }}
                    />
                </div>
            </div>
        </>
    );
};

interface SelectOptions {
    label: string;
    value: number;
}
