import { PermissionName } from './permission.name';
import { UserRole } from './user-role';

export class User {
    isAuthenticated: boolean;
    userName: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    emailAddress: string;
    phone: string;
    userGUID: string;
    password?: string;
    confirmPassword?: string;
    userRoles: UserRole[];
    currentPermissions: PermissionName[];
}
