import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import axios from "axios";
import { useLoadData } from "../hooks/use-load-data.hook";
import { LoadingIndicator } from "./loading-indicator.component";
import { EditTemplate } from "../features/building/window/edit-template.component";
import { TemplateModel } from "../models/template-model";
import { DataResult, process } from "@progress/kendo-data-query";
import DoorGray from "../images/door-gray";
import DeleteIcon from "../images/delete";
import EditIcon from "../images/edit";
import { Railing, Window } from "../images/window-door";
import { Building } from "../models/claim/building";
import { SubmitStatus } from "./formik-model-dialog.component";
import { WINDOWS_DOORS_API_URL } from "../constants";
import { capitalize } from "lodash";
import StarIcon from "../images/star-image";
import ReportIcon from "../images/report";
import { InspectionReportIcon, StarBlank } from "../images/report-icon.component";
import { TemplateDefault } from "../models/template-default";
import { fail } from "assert";
import CustomToast from "./toast.component";


interface IDefaultTabComponentProps {
    building: Building;
}

const DefaultTabComponent: React.FC<IDefaultTabComponentProps> = ({ building }) => {
    const initialAddTemplate: TemplateModel = {

        templateDefaultID: 0,
        templateID: 0,
        templateName: "",
        direction: '',
        // notes: '',
        attributes: [],
        buildingId: 0,
        templateEntityType: "window",
        UserGuid: "",
        isActive: false,
        createdOn: "",
        // hasDamaged: false,
    };
    const [selectedEntity, setSelectedEntity] = useState<string>("window");
    const [isModal, setIsModal] = useState<boolean>(false);
    const [window, setWindow] = useState<TemplateModel>(initialAddTemplate);
    const { isLoading, reload, result } = useLoadData<any[]>(`TemplateAsset/GetGetTemplateAssetsByEntityType/${building.buildingID}/${selectedEntity}`);
    const [doorColor, setDoorColor] = useState<boolean[]>([true, false, false]);
    const [templateList, setTemplateList] = useState<TemplateModel[]>([]);
    const [deleteItem, setDeleteItem] = useState<any>(null);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [toast, showToast] = useState<boolean>(false);
    useEffect(() => {

        if (result && !isLoading) {
            setTemplateList(result);

        }
    }, [result]);



    useEffect(() => {
        reload();
    }, [selectedEntity]);

    const iconList: React.ReactNode[] = doorColor.map((selected, index) => {
        const color = selected ? "white" : "grey";

        switch (index % 3) {
            case 0:
                return <Window color={color} key={index} />;
            case 1:
                return <DoorGray color={color} key={index} />;
            case 2:
                return <Railing color={color} key={index} />;
            default:
                return null;
        }
    });

    const entityLabels = ["window", "door", "railing"];



    const handleEntityClick = (index: number) => {
        setDoorColor(doorColor.map((_, i) => i === index));
        setSelectedEntity(entityLabels[index]);
    };

    function handleSaveWindowComplete(status: SubmitStatus, data: any) {
        if (status === SubmitStatus.Completed) {
            reload();
        }
        setIsModal(false);
        setWindow(initialAddTemplate);
    }

    const handleEditClick = (dataItem: TemplateModel) => {
        setWindow(dataItem);
        setIsModal(true);
    };

    const handleDeleteClick = (dataItem: TemplateModel) => {
        setDeleteItem(dataItem);
        setDialogVisible(true);
    };
    const handledefault = async (dataItem: TemplateDefault) => {
        const response = await axios.post(`${WINDOWS_DOORS_API_URL}/api/TemplateAsset/SaveTemplateDefault`, dataItem);
        if (response.status === 200) {

            reload();
        }
        else {
            console.log(response.data);

        }
    }

    const onCancel = () => {
        setDeleteItem(null);
        setDialogVisible(false);
    };

    const onDelete = async () => {
        if (deleteItem) {
            try {
                await axios.delete(`${WINDOWS_DOORS_API_URL}/api/TemplateAsset/DeleteTemplate/${deleteItem.templateID}`);
                reload();

            } catch (error) {
                console.error("Failed to delete template:", error);
            }
        }
        setDeleteItem(null);
        setDialogVisible(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between mt-2 mb-2 stack_title1 align-items-end">

                <h4>Asset Templates</h4>    <button className="btn btn-outline-primary btn-sm" onClick={() => setIsModal(true)}>Add Template</button>

            </div>

            <div className="template-container" >

                <div style={{}}>
                    {doorColor.map((selected, i) => (
                        <div
                            key={i}
                            onClick={() => handleEntityClick(i)}
                            className="icons-template"
                            style={
                                selected
                                    ? { backgroundColor: "orange", color: "white", textAlign: 'center', cursor: 'pointer' }
                                    : { backgroundColor: "white", color: "grey", textAlign: 'center', cursor: 'pointer' }

                            }
                        ><center>
                                {iconList[i]}
                                <small>
                                    {capitalize(entityLabels[i])}
                                </small>
                            </center>
                        </div>
                    ))}

                </div>
                <div>
                    {!isLoading ? <div style={{ maxHeight: "500px", overflow: 'auto' }} className="templateGrid">
                        {templateList.map(e => {
                            return (
                                <div className="taplete-itemWrap">
                                    <div>
                                        <small>Template Name</small>
                                        <label id='liveToastBtn' style={{ cursor: 'pointer' }}> {templateList.length > 0 && (e.templateDefaultID > 0 ? (<StarIcon />) : <StarBlank onClick={async () => {
                                            await handledefault({
                                                buildingID: building.buildingID,
                                                templateDefaultID: 0, templateEntityType: selectedEntity, templateID: e.templateID
                                            });

                                        }} />)} {`${e.templateName} `} </label>
                                    </div>
                                    <CustomToast content="Set As Default Template Successfully" heading="Success" />

                                    <div>
                                        <small>Created On</small>
                                        <label>{e.createdOn}</label>
                                    </div>
                                    <div>
                                        <a style={{ cursor: 'pointer' }} className="mx-3" onClick={() => handleEditClick(e)}>
                                            <EditIcon />
                                        </a>
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(e)} className="mx-3">
                                            <DeleteIcon />
                                        </a>
                                    </div>
                                </div >)




                        })}</div > :
                        <center><LoadingIndicator isLoading={true} /></center>
                    }


                </div >

            </div>


            {isModal && (
                <EditTemplate isTemplate onClose={handleSaveWindowComplete} data={{ building, template: window, copyFrom: false }} entityType={selectedEntity} />
            )
            }
            {
                dialogVisible && (
                    <Dialog title="Confirm Delete" onClose={onCancel}>
                        <p>Are you sure you want to delete this template?</p>
                        <DialogActionsBar>
                            <button className="btn btn-secondary ms-auto btn-sm" onClick={onCancel}>Cancel</button>
                            <button className="btn btn-primary ms-auto btn-sm" onClick={onDelete}>Delete</button>
                        </DialogActionsBar>
                    </Dialog>
                )
            }

        </>
    );
};

export default DefaultTabComponent;