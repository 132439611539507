import { ErrorMessage, Field, useFormikContext } from 'formik';

interface SelectFieldProps {
    name: string;
    label?: string;
    options?: { value: string; label: string }[];
    unSelectValue?: any;
    notifyChange?: any;
}

export const SelectField = ({
    name,
    label,
    options,
    unSelectValue,
    notifyChange,
}: SelectFieldProps): any => {
    const { setFieldValue } = useFormikContext();
    const handleChange = (e: any) => {
        setFieldValue(name, e.target.value);
        if (notifyChange) notifyChange(e.target.value);
    };

    return (
        <div>
            {label && <label className="label">{label}</label>}
            <div className="select">
                <Field
                    as="select"
                    name={name}
                    className="form-control  mb-3"
                    onChange={handleChange}
                >
                    {/* <option label="--Please Select--" value={unSelectValue} /> */}
                    {options?.map((o) => {
                        return <option key={o.value} value={o.value} label={o.label} />;
                    })}
                </Field>
            </div>
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
