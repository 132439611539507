import { find, isNil, map } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { ClaimObservation } from '../../../models/claim/claim-observation';

import { ObservationListComponent } from './observation-list.component';
import { Building } from '../../../models/claim/building';
import { GetBuildingList } from '../../../api-client.ts/buildingapi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { GetBuildings } from '../../../redux/actions/buildingactions';
import { GetClaimObservationForEntity } from '../../../redux/actions/claimobservationaction';
import { RequestClaimObservation } from '../../../models/api-request.models';

interface CopyBuildingObservationsComponentProps {
    buildingID: number;
    isInterior: boolean;
    onCopyObservation: (observation: ClaimObservation) => any;
    buildingsList: Building[];
}

export const CopyBuildingObservationsComponent = ({
    buildingID,
    isInterior,
    onCopyObservation,
    buildingsList,
}: CopyBuildingObservationsComponentProps) => {
    const [building, setSelectedItem] = useState<Building>();
    const dispatch = useDispatch<AppDispatch>();
    const [claimObs, setClaimObs] = useState<ClaimObservation[]>([]);
    useEffect(() => {
        LoadBuildingObservations().then((value) => {

            let modifieddata = JSON.parse(JSON.stringify(value.payload as ClaimObservation[]));
            let filtereddata = (modifieddata as ClaimObservation[]).filter(e => e.isInterior === isInterior)
            setClaimObs(filtereddata);
        });
        if (isNil(buildingID)) {
            setSelectedItem(null);
        }
    }, [buildingID]);
    const claimObsObj = useRef<RequestClaimObservation>({
        entityID: buildingsList[0].buildingID,
        entityType: 'building',
    });
    const LoadBuildingObservations = async () => {
        return await dispatch(GetClaimObservationForEntity(claimObsObj.current));
    };

    const selectedOption = { label: building?.buildingName, value: building?.buildingID };

    const options = map(buildingsList, (b) => {
        return {
            label: b.buildingName,
            value: b.buildingID,
        };
    });

    const handleBuildingSelectionChange = async (selected: any) => {
        const selectedItem: any = find(buildingsList, (b) => b.buildingID === selected?.value);
        setSelectedItem(selectedItem);
        claimObsObj.current = { entityID: selected.value, entityType: 'building' };
        await LoadBuildingObservations().then((value) => {
            let modifieddata = JSON.parse(JSON.stringify(value.payload as ClaimObservation[]));
            setClaimObs(modifieddata);
        });
    };

    /*  if (isLoading) {
        return <LoadingIndicator isLoading={isLoading} />;
    } */

    return (
        <div className='copyObservation'>
            <label>Buildings </label>
            <Select
                value={selectedOption}
                options={options}
                onChange={handleBuildingSelectionChange}
            />

            <div className="copy-observation-list">
                <ObservationListComponent
                    ispopup
                    observations={claimObs}
                    onCopyObservation={onCopyObservation}
                    onShowObservationPhotos={() => { }}
                />
            </div>
        </div>
    );
};
