import { find, isEmpty, isNil, map } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { Room } from '../../../models/claim/room';
import { UnitWindow } from '../../../models/claim/unit-window';
import { ObservationListComponent } from './observation-list.component';

interface CopyWindowObservationsComponentProps {
    roomId: number;
    onCopyObservation: (observation: ClaimObservation) => any;
}

export const CopyWindowObservationsComponent = ({
    roomId,
    onCopyObservation,
}: CopyWindowObservationsComponentProps) => {
    const [window, setSelectedItem] = useState<UnitWindow>();
    const { isLoading, result, isError } = useLoadData<Room>(
        `rooms?roomid=${roomId}`,
        isNil(roomId),
    );

    useEffect(() => {
        if (result && result.windows && !isEmpty(result.windows)) {
            setSelectedItem(result.windows[0]);
        }
    }, [result]);

    useEffect(() => {
        if (isNil(roomId)) {
            setSelectedItem(null);
        }
    }, [roomId]);

    const selectedOption = { label: window?.windowName, value: window?.windowID };

    const options = map(result?.windows, (w) => {
        return {
            label: w.windowName,
            value: w.windowID,
        };
    });

    const handleWindowSelectionChange = (selected: any) => {
        const selectedItem: any = find(result.windows, (w) => w.windowID === selected?.value);
        setSelectedItem(selectedItem);
    };

    if (isLoading) {
        return <LoadingIndicator isLoading={isLoading} />;
    }

    return (
        <div className='copyObservation'>
            <label> Window </label>
            <Select
                value={selectedOption}
                options={options}
                onChange={handleWindowSelectionChange}
            />
            <label>  Observations</label>
            <div className="copy-observation-list">
                <ObservationListComponent
                    observations={window?.observations}
                    onCopyObservation={onCopyObservation}
                    ispopup
                    onShowObservationPhotos={() => { }}
                />
            </div>
        </div>
    );
};
