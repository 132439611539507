import { useState } from 'react';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';
import { Unit } from '../../../models/claim/unit';
import { EditUnit } from './edit-unit.component';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { Floor } from '../../../models/claim/floor';
import { Building } from '../../../models/claim/building';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { PermissionName } from '../../../models/user-security/permission.name';
import EditIcon from '../../../images/edit';

interface UnitComponentProps {
    currentBuilding: Building;
    currentFloor: Floor;
    selectUnit: any;
    onEditUnit: any;
    onUnitDeleted: (unit: Unit) => any;
    onUnitSelect: Function;
}

export const UnitComponent = ({
    currentBuilding,
    currentFloor,
    selectUnit,
    onEditUnit,
    onUnitDeleted,
    onUnitSelect

}: UnitComponentProps) => {
    const [editData, setEditData] = useState<Unit>();
    const [searchKey, setSearchKey] = useState<string>();

    const units = currentFloor?.units.filter((f) => {
        return searchKey ? f.unitName.toLowerCase().search(searchKey.toLowerCase()) > -1 : true;
    });

    const onItemClick = (event: ListBoxItemClickEvent) => {
        onUnitSelect(event.dataItem)
        selectUnit(event.dataItem);
    };

    const onSearch = (e: any) => {
        setSearchKey(e.target.value);
    };

    const onAddUnit = () => {
        if (currentFloor) {
            const unit: Unit = {
                floorID: currentFloor?.floorID,
                unitID: 0,
            };
            setEditData(unit);
        }
    };

    const onEditUnitClick = (unit: Unit) => {
        setEditData(unit);
    };

    const onSaveComplete = (status: SubmitStatus, data: Unit) => {
        if (status == SubmitStatus.Completed) {
            onEditUnit(data);
        }
        setEditData(null);
    };

    const handleFloorDeleted = (unit: Unit) => {
        setEditData(null);
        onUnitDeleted(unit);
    };

    const templatedItem = (props: any) => {
        let { dataItem, selected, ...others } = props;
        return (
            <li {...others}>
                <div className='d-flex w-100'>
                    <div>
                        <span className='fontMedium'>
                            {props.dataItem.unitName} ({props.dataItem.unitNumber})
                        </span>
                        <div >
                            Rooms: {props.dataItem.rooms.length}
                        </div>
                    </div>
                    <GuardedComponent permission={PermissionName.AddUnit}>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditUnitClick(props.dataItem);
                            }}
                            className="action-btns k-grid-edit-command ms-auto"
                        >
                            <EditIcon></EditIcon>   {/* <img src={iconEdit} title="Edit" /> */}
                        </button>
                    </GuardedComponent>
                </div>
            </li>
        );
    };

    return (
        <div>
            <div className="d-flex mb-2">
                <h5>UNIT</h5>
                <GuardedComponent permission={PermissionName.AddUnit}>
                    <button className="add-edit-btn ms-auto" onClick={onAddUnit}>
                        Add Unit
                    </button>
                </GuardedComponent>
            </div>
            <div>
                <input
                    type="text"
                    className="form-control mb-2 searchBg gray-bg"
                    placeholder="Search"
                    onChange={onSearch}
                />
            </div>
            {currentFloor?.units && (
                <ListBox
                    data={units}
                    item={templatedItem}
                    textField="unitName"
                    selectedField="isSelected"
                    onItemClick={(e: ListBoxItemClickEvent) => onItemClick(e)}
                />
            )}
            {editData && (
                <EditUnit
                    data={{ building: currentBuilding, unit: editData }}
                    onClose={onSaveComplete}
                    onDeleted={handleFloorDeleted}
                />
            )}
        </div>
    );
};
