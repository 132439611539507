import { DataResult, State, process } from '@progress/kendo-data-query';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridColumnMenuFilter,
    GridColumnMenuProps,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import './observations.scss';
import imageimg from '../../../images/file-image';
import EyeIcon from '../../../images/eye';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { EntityType } from '../../../models/claim/entity-type';
import { ObservationDetail } from '../../../models/reports/observation-detail';
import { ClaimSetupBreadCrumb } from '../../../components/claim-setup-breadcrumb';
import { useStorage } from '../../../hooks/use-storage';
import { StorageKey } from '../../../constants';
import { Claim } from '../../../models/claim/claim';
import { ImageListModelDialog } from '../../image/image-list-model-dialog.component';
import { Button } from 'react-bootstrap';
import FileImage from '../../../images/file-image';

export const ObservationsPage = (/* props: { claimGuid: string; match: any } */) => {
    let gridPDFExport: GridPDFExport | null;
    const initialDataState: State = {
        take: 10,
        skip: 0,
        group: [],
    };
    const { claimGuid } = useParams();
    const processWithGroups = (data: ObservationDetail[], dataState: State) => {
        const groups = dataState.group;
        dataState.group = groups;
        const newDataState = process(data, dataState);
        setGroupIds({ data: newDataState.data, group: dataState.group });
        return newDataState;
    };
    const history = useNavigate();
    const { isLoading, result, isError } = useLoadData<ObservationDetail[]>(
        `reports/observations/${/* props.match.params.claimGuid */ claimGuid}`,
    );
    const [collapsedState, setCollapsedState] = useState<string[]>([]);
    const [resultData, setResultData] = useState<DataResult>();
    const [dataState, setDataState] = useState<State>(initialDataState);
    const [imageListData, setImageListData] = useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    const [currentClaim] = useStorage(StorageKey.CurrentUser, new Claim());
    const [customFilterArray, setCustomFilterArray] = useState<any[]>([]);

    const exportExcel = useRef<ExcelExport | null>(null);

    useEffect(() => {
        if (result) {
            setResultData(processWithGroups(result, initialDataState));
        }
    }, [result]);

    const exportPDF = () => {
        setTimeout(() => {
            if (gridPDFExport) {
                gridPDFExport.save(
                    processWithGroups(result, {
                        take: 10000,
                        skip: 0,
                        group: dataState.group,
                    }).data,
                );
            }
        }, 100);
    };

    const excelExport = () => {
        if (exportExcel.current !== null) {
            exportExcel.current.save();
        }
    };

    const openGallery = (data: { entityType: EntityType; entityId: number }) => {
        setImageListData(data);
    };

    const closeGallery = () => {
        setImageListData(null);
    };

    const onView = (buildingID: number) => {
        history(`/buildingdetails/${buildingID}`);
    };

    const gotoClaimDetails = () => {
        history(`/buildings/${/* props.match.params.claimGuid */ claimGuid}`);
    };

    const onSearch = (e: any) => {
        if (e.target.value.length > 0) {
            const searchKey: string = e.target.value;
            setResultData(
                processWithGroups(
                    result.filter(
                        (p) =>
                            p.observation.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.observationOption.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.optionValue.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.buildingName.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.entityType.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.entityName.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.unitName.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.unitNumber.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.floorName.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.floorNumber.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.roomNumber.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.direction.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.roomName.toLowerCase().includes(searchKey.toLowerCase()),
                    ),
                    dataState,
                ),
            );
        } else {
            setResultData(processWithGroups(result, dataState));
        }
    };

    const PageTemplate = (props: any) => {
        return (
            <>
                <div className="report-header">KeyStone Windows&Doors</div>
                <div className="report-footer">
                    Page {props.pageNum} of {props.totalPages}
                </div>
            </>
        );
    };

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuFilter {...props} />
            </div>
        );
    };

    const expandChange = (event: GridExpandChangeEvent) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = processWithGroups(result, event.dataState);
        setResultData(newDataState);
        setDataState(event.dataState);
        setCustomFilterArray(event.dataState?.filter?.filters);
    };

    const clearFilter = (field: any) => {
        if (dataState?.filter?.filters) {
            dataState.filter?.filters.splice(
                dataState.filter?.filters.findIndex((obj: any) => obj.filters[0].field === field),
                1,
            );
            setDataState(dataState);
            setResultData(processWithGroups(result, dataState));
        }
    };

    const getColumnTitle = (field: any) => {
        let columnTitle = field;
        switch (field) {
            case 'buildingName':
                columnTitle = 'Building Name';
                break;
            case 'floorName':
                columnTitle = 'Floor Name';
                break;
            case 'unitName':
                columnTitle = 'Unit Name';
                break;
            case 'roomNumber':
                columnTitle = 'Room Number';
                break;
            case 'roomName':
                columnTitle = 'Room Name';
                break;
            case 'entityType':
                columnTitle = 'Type';
                break;
            case 'entityName':
                columnTitle = 'Name';
                break;
            case 'direction':
                columnTitle = 'Direction';
                break;
            case 'observation':
                columnTitle = 'Observation Type';
                break;
            case 'observationOption':
                columnTitle = 'Observation';
                break;
            case 'optionValue':
                columnTitle = 'Value';
                break;
            case 'isEventRelated':
                columnTitle = 'Event Related';
                break;
        }
        return columnTitle;
    };

    const CommandCell = (props: GridCellProps) => (
        <td className="k-command-cell text-center">
            <div className="border-start d-flex align-items-center">
                <button
                    className="action-btns k-grid-edit-command"
                    onClick={() => onView(props.dataItem.buildingID)}
                >
                    <EyeIcon></EyeIcon> {/* <img src={iconEye} alt='view' title="View" /> */}
                </button>
                <button
                    className="tag-buttons"
                    onClick={(e) => {
                        e.stopPropagation();
                        openGallery({
                            entityType: EntityType.Observation,
                            entityId: props.dataItem.observationID,
                        });
                    }}
                >
                    <FileImage></FileImage> {/* <img src={imageimg} /> */}
                    <span className="tagtop">{props.dataItem.images}</span>
                </button>
            </div>
        </td>
    );

    let newData = null;
    if (resultData) {
        newData = setExpandedState({
            data: resultData.data,
            collapsedIds: collapsedState,
        });
    }

    let grid = null;
    if (newData) {
        grid = (
            <Grid
                data={newData}
                pageable={{ pageSizes: [10, 20, 50, 100] }}
                total={resultData.total}
                groupable={true}
                sortable={true}
                onDataStateChange={dataStateChange}
                {...dataState}
                onExpandChange={expandChange}
                expandField="expanded"
            >
                <GridToolbar>
                    <div>
                        {customFilterArray && (
                            <>
                                {customFilterArray.map((filter) => {
                                    if (filter.filters.length > 1) {
                                        return (
                                            <>
                                                <span>
                                                    {' ' +
                                                        getColumnTitle(filter.filters[0]?.field) +
                                                        ' ' +
                                                        filter.filters[0]?.operator +
                                                        ' ' +
                                                        filter.filters[0]?.value +
                                                        ' ' +
                                                        filter.logic +
                                                        ' ' +
                                                        filter.filters[1]?.operator +
                                                        ' ' +
                                                        filter.filters[1]?.value}
                                                </span>

                                                <button
                                                    className="action-btns mt-0 pt-0"
                                                    onClick={() =>
                                                        clearFilter(filter.filters[0]?.field)
                                                    }
                                                >
                                                    X
                                                </button>
                                            </>
                                        );
                                    } else {
                                        return (
                                            <>
                                                <span>
                                                    {' ' +
                                                        getColumnTitle(filter.filters[0]?.field) +
                                                        ' ' +
                                                        filter.filters[0]?.operator +
                                                        ' ' +
                                                        filter.filters[0]?.value}
                                                </span>

                                                <button
                                                    className="action-btns mt-0 pt-0"
                                                    onClick={() =>
                                                        clearFilter(filter.filters[0]?.field)
                                                    }
                                                >
                                                    X
                                                </button>
                                            </>
                                        );
                                    }
                                })}
                            </>
                        )}
                    </div>
                </GridToolbar>
                <GridToolbar>
                    <button title="Export PDF" className="btn btn-outline-primary btn-sm align-self-end" onClick={exportPDF}>
                        Export PDF
                    </button>
                    <button
                        title="Export Excel"
                        className="btn btn-outline-primary btn-sm align-self-end"
                        onClick={excelExport}
                    >
                        Export to Excel
                    </button>
                </GridToolbar>
                <GridColumn field="buildingName" title="Building Name" columnMenu={ColumnMenu} />
                <GridColumn field="floorName" title="Floor Name" columnMenu={ColumnMenu} />
                <GridColumn field="unitName" title="Unit Name" columnMenu={ColumnMenu} />
                <GridColumn field="roomNumber" title="Room Number" columnMenu={ColumnMenu} />
                <GridColumn field="roomName" title="Room Name" columnMenu={ColumnMenu} />
                <GridColumn field="entityType" title="Type" columnMenu={ColumnMenu} />
                <GridColumn field="entityName" title="Name" columnMenu={ColumnMenu} />
                <GridColumn field="direction" title="Direction" columnMenu={ColumnMenu} />
                <GridColumn field="observation" title="Observation Type" columnMenu={ColumnMenu} />
                <GridColumn field="observationOption" title="Observation" columnMenu={ColumnMenu} />
                <GridColumn field="optionValue" title="Value" columnMenu={ColumnMenu} />
                <GridColumn field="isEventRelated" title="Event Related" columnMenu={ColumnMenu} />
                <GridColumn cell={CommandCell} width="100px" groupable={true} />
            </Grid>
        );
    }

    if (isError) {
        return <div>Error</div>;
    }
    return (
        <div className="container-fluid">
            <div className="top-sec-align">
                <div>
                    <ClaimSetupBreadCrumb currentClaim={currentClaim} />
                </div>
                <input
                    type="text"
                    className="form-control ms-auto search-right-align searchBg"
                    onChange={onSearch}
                />

                <Button className="ms-3" onClick={gotoClaimDetails}>
                    Claim Details
                </Button>
            </div>
            <LoadingIndicator isLoading={isLoading} />
            <div className="card">
                {newData && (
                    <>
                        <ExcelExport
                            data={
                                processWithGroups(result, {
                                    take: 10000,
                                    skip: 0,
                                    group: dataState.group,
                                    //filter: dataState.filter,
                                }).data
                            }
                            ref={exportExcel}
                            group={dataState.group}
                        >
                            {grid}
                        </ExcelExport>
                        <GridPDFExport
                            ref={(pdfExport) => (gridPDFExport = pdfExport)}
                            pageTemplate={PageTemplate}
                            margin="1.2cm"
                            paperSize="A4"
                            landscape={true}
                        >
                            <GridColumn field="buildingName" title="Building Name" />
                            <GridColumn field="floorName" title="Floor Name" />
                            <GridColumn field="unitName" title="Unit Name" />
                            <GridColumn field="roomNumber" title="Room Number" />
                            <GridColumn field="roomName" title="Room Name" />
                            <GridColumn field="entityType" title="Type" />
                            <GridColumn field="entityName" title="Name" />
                            <GridColumn field="direction" title="Direction" />
                            <GridColumn
                                field="observation"
                                title="Observation Type"
                                headerClassName="wrapped-header"
                            />
                            <GridColumn field="observationOption" title="Observation" />
                            <GridColumn field="optionValue" title="Value" />
                            <GridColumn
                                field="isEventRelated"
                                title="Event Related"
                                headerClassName="wrapped-header"
                            />
                            {grid}
                        </GridPDFExport>
                    </>
                )}
            </div>
            {imageListData && <ImageListModelDialog data={imageListData} onCancel={closeGallery} />}
        </div>
    );
};
