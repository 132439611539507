import { Dialog } from '@progress/kendo-react-dialogs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { GuardedComponent } from '../../components/guarded.componemt';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { ClaimObservation } from '../../models/claim/claim-observation';
import { ClaimObservationOption } from '../../models/claim/claim-observation-option';
import { EntityType } from '../../models/claim/entity-type';
import { UnitDoor } from '../../models/claim/unit-door';
import { UnitWindow } from '../../models/claim/unit-window';
import { ObservationType } from '../../models/masters/observation-type';
import { PermissionName } from '../../models/user-security/permission.name';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import { EditObservation } from '../building/observation/edit-observation.component';
import { ObservationListComponent } from '../building/observation/observation-list.component';
import { ImageListModelDialog } from '../image/image-list-model-dialog.component';
import imageimg from '../../images/file-image';
import { Building } from '../../models/claim/building';
import { EditWindowDoorWrapperComponent } from './edit-window-door-wrapper.component';
import { DeleteWindowDoorWrapperComponent } from './delete-window-door-wrapper.component';
import FileImage from '../../images/file-image';

interface ViewEntityDetailsModelDialogProps {
    buildingData: Building;
    entityData: { entityType: EntityType; entityId: number };
    onUpdate: (
        isDirty: boolean,
        enitity: { entityType: EntityType; entityId: number },
        observations: ClaimObservation[],
    ) => any;
    onEntityDelete: (enitity: { entityType: EntityType; entityId: number }) => any;
}

export const ViewEntityDetailsModelDialog = ({
    buildingData,
    entityData,
    onUpdate,
    onEntityDelete,
}: ViewEntityDetailsModelDialogProps) => {
    const [observations, setObservations] = useState<ClaimObservation[]>();
    const [newObservation, setNewObservation] = useState<{
        newObservation: ClaimObservation;
        updateOption: ClaimObservationOption;
    }>();
    const [imageListData, setImageListData] = useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    const [deleteObservation, setDeleteObservation] = useState<DeletePostDataInfo>();
    const [editEntity, setEditEntity] = useState<Boolean>(false);
    const [deleteEntity, setDeleteEntity] = useState<Boolean>(false);
    const { isLoading, result, isError } = useLoadData<any>(
        `${entityData.entityType}s/details/${entityData.entityId}`,
    );
    useEffect(() => {
        if (result) {
            setObservations(result.observations);
        }
    }, [result]);

    const handleClose = () => {
        onUpdate(true, result, observations);
    };

    const openGallery = (data: { entityType: EntityType; entityId: number }) => {
        setImageListData(data);
    };

    const closGallery = () => {
        setImageListData(null);
    };

    const getEntityInfo = (): any => {
        if (!result) {
            return null;
        }
        if (!isNil(result.windowID)) {
            return getWindowInfo(result);
        } else if (!isNil(result.doorID)) {
            return getDoorInfo(result);
        }
    };

    const getWindowInfo = (window: UnitWindow) => {
        return <div>Window Name: {window.windowName}</div>;
    };

    const getDoorInfo = (door: UnitDoor) => {
        return <div>Door Name: {door.doorName}</div>;
    };

    const onEditWindowClick = () => {
        if (result) {
            setEditEntity(true);
        }
    };

    const onDeleteWindowClick = () => {
        if (result) {
            setDeleteEntity(true);
        }
    };

    const getObservationType = () => {
        return entityData.entityType.toLocaleLowerCase() === EntityType.Window.toLocaleLowerCase()
            ? ObservationType.Window
            : ObservationType.Door;
    };

    const onAddObservationClick = () => {
        const observation = new ClaimObservation();
        observation.observationID = 0;
        observation.entityType = getObservationType();
        observation.entityID = entityData.entityId;
        setNewObservation({
            newObservation: observation,
            updateOption: null,
        });
    };

    const onEditObservationClick = (
        observation: ClaimObservation,
        updateOption: ClaimObservationOption,
    ) => {
        setNewObservation({
            newObservation: observation,
            updateOption: updateOption,
        });
    };
    const onDeleteObservation = (claimObservation: ClaimObservation) => {
        setDeleteObservation({
            data: claimObservation,
            route: `ClaimObservation/${claimObservation.observationID}`,
        });
    };

    const handleSaveObservationComplete = (status: SubmitStatus, data: ClaimObservation) => {
        if (status === SubmitStatus.Completed) {
            const updatedObsrvations = addOrUpdateArrayItem(observations, data, 'observationID');
            setObservations(updatedObsrvations);
        }
        setNewObservation(null);
    };

    const handleDeleteObservationComplete = (status: DeleteStatus, data: ClaimObservation) => {
        if (status == DeleteStatus.Completed) {
            const updatedObsrvations = deleteArrayItem(observations, data, 'observationID');
            setObservations(updatedObsrvations);
        }
        setDeleteObservation(null);
    };

    const handleDeleteWindowComplete = (status: DeleteStatus, data: any) => {
        if (status == DeleteStatus.Completed) {
            onEntityDelete(data);
        }
        setDeleteEntity(false);
    };

    const handleEditWindowComplete = (status: SubmitStatus, data: any) => {
        setEditEntity(false);
    };

    if (editEntity) {
        return (
            <EditWindowDoorWrapperComponent
                building={buildingData}
                entity={result}
                onClose={handleEditWindowComplete}
            />
        );
    }

    if (deleteEntity) {
        return (
            <DeleteWindowDoorWrapperComponent
                building={buildingData}
                entity={entityData}
                onClose={handleDeleteWindowComplete}
            />
        );
    }

    if (deleteObservation) {
        return (
            <DeleteModelDialog
                title="Delete Observation"
                deleteData={deleteObservation}
                onClose={handleDeleteObservationComplete}
            >
                <div>
                    Are you sure you want to delete Observation -{' '}
                    {deleteObservation.data.observation}?
                </div>
            </DeleteModelDialog>
        );
    }

    if (newObservation) {
        return (
            <EditObservation
                observationType={getObservationType()}
                building={buildingData}
                data={newObservation}
                onClose={handleSaveObservationComplete}
            />
        );
    }

    if (imageListData) {
        return <ImageListModelDialog data={imageListData} onCancel={closGallery} />;
    }

    return (
        <Dialog title={`${entityData.entityType} Details`} onClose={handleClose}>
            <div>
                {result && (
                    <div>
                        <h5>
                            <b>{getEntityInfo()} </b>
                        </h5>
                        <div className="options-list d-flex">
                            {result.attributes?.map((attribute: any) => (
                                <label>{attribute.attributeValue} | </label>
                            ))}
                            <div className="d-flex mb-2 ms-2">
                                <button
                                    className="tag-buttons"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openGallery({
                                            entityType: entityData.entityType,
                                            entityId: entityData.entityId,
                                        });
                                    }}
                                >
                                 <FileImage></FileImage> {/*   <img src={imageimg} /> */}
                                    <span className="tagtop">{result?.images}</span>
                                </button>
                            </div>
                        </div>

                        <div className="d-flex mb-2">
                            <div className="ms-auto">
                                <GuardedComponent permission={PermissionName.AddObservation}>
                                    <button
                                        title="Add Observation"
                                        className="btn btn-primary btn-sm"
                                        onClick={onAddObservationClick}
                                    >
                                        Add Observation
                                    </button>
                                </GuardedComponent>
                            </div>
                            <div className="ms-2">
                                <GuardedComponent permission={PermissionName.EditWindow}>
                                    <button
                                        title="Edit Window"
                                        className="btn btn-primary edit-window-btn btn-sm"
                                        onClick={onEditWindowClick}
                                    >
                                        Edit
                                    </button>
                                </GuardedComponent>
                            </div>
                            <div className="ms-2">
                                <GuardedComponent permission={PermissionName.DeleteWindow}>
                                    <button
                                        title="Delete Window"
                                        className="btn btn-secondary add-window-btn btn-sm"
                                        onClick={onDeleteWindowClick}
                                    >
                                        Delete
                                    </button>
                                </GuardedComponent>
                            </div>
                        </div>
                        <ObservationListComponent
                            observations={observations}
                            onEditObservation={onEditObservationClick}
                            onDeleteObservation={onDeleteObservation}
                            onOpenGallery={openGallery}
                        />
                    </div>
                )}
                {isError && <div>Error</div>}
                <LoadingIndicator isLoading={isLoading} />
            </div>
        </Dialog>
    );
};
