import { useLoadData } from '../../hooks/use-load-data.hook';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { GridCommandCell } from '../../components/grid-command-cell.component';
import { useEffect, useState } from 'react';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { EditMaster } from './edit-master.component';
import { MasterValue } from '../../models/masters/master-value';
import { PermissionName } from '../../models/user-security/permission.name';

export const MasterGridComponent = (props: {
    masterType: string;
    masterTypeID: number;
    attributeName: string;
}) => {
    const [editMasterValue, setEditMasterValue] = useState<MasterValue>();
    const [deleteMasterValue, setDeleteMasterValue] = useState<DeletePostDataInfo>();
    const [gridData, setGridData] = useState<MasterValue[]>();
    const { isLoading, result, isError } = useLoadData<MasterValue[]>(
        `masters/${props.masterType}/${props.attributeName}`,
    );
    useEffect(() => {
        if (result) {
            setGridData(result);
        }
    }, [result]);

    const onSaveComplete = (status: SubmitStatus, data: MasterValue) => {
        if (status == SubmitStatus.Completed) {
            const newData = addOrUpdateArrayItem(gridData, data, 'id');
            setGridData(newData);
        }
        setEditMasterValue(null);
    };

    const onDeleteComplete = (status: DeleteStatus, data: MasterValue) => {
        if (status == DeleteStatus.Completed) {
            const newData = deleteArrayItem(gridData, data, 'id');
            setGridData(newData);
        }
        setDeleteMasterValue(null);
    };

    const onEnterEdit = (data: MasterValue) => {
        setEditMasterValue({ ...data });
    };

    const onAddNew = () => {
        const masterValue: MasterValue = {
            id: 0,
            value: '',
            masterTypeID: props.masterTypeID,
        };
        setEditMasterValue(masterValue);
    };

    const onDelete = (data: MasterValue) => {
        const { id } = data;
        setDeleteMasterValue({
            data,
            route: `masters/delete/${id}`,
        });
    };

    const CommandCell = (props: GridCellProps) => (
        <GridCommandCell
            {...props}
            edit={onEnterEdit}
            remove={onDelete}
            add={onAddNew}
            idPropertyName="ID"
            viewPermissionName={PermissionName.ViewMaster}
            editPermissionName={PermissionName.EditMaster}
            deletePermissionName={PermissionName.DeleteMaster}
        />
    );

    if (isError) {
        return <div>Error</div>;
    }
    return isLoading ? (
        <div className="text-center">Loading</div>
    ) : (
        <>
            <div className="d-flex">
                <button
                    title="Add new"
                    className="btn btn-outline-primary ms-auto mb-2 mt-2 btn-sm"
                    onClick={onAddNew}
                >
                    Add new Options
                </button>
            </div>
            <Grid data={gridData}>
                {/*<GridColumn width="100" field="id" title="ID" editable={false} />*/}
                <GridColumn field="value" title="OPTIONS" />
                <GridColumn cell={CommandCell} width="150px" />
            </Grid>
            {editMasterValue && <EditMaster data={editMasterValue} onClose={onSaveComplete} />}
            {deleteMasterValue && (
                <DeleteModelDialog
                    title="Delete Option"
                    deleteData={deleteMasterValue}
                    onClose={onDeleteComplete}
                >
                    <div className='text-center'>
                        <h5 className='mt-3'>Are you sure you want to delete {props.attributeName} <b className='d-block color-primary'>{deleteMasterValue.data.value}?</b></h5>

                    </div>
                </DeleteModelDialog>
            )}
        </>
    );
};
