import { Route, Routes } from 'react-router-dom';
import GuardedRoute from './guarded-route';
import { HomePage } from '../features/home/home.page';
import { UnauthorizedPage } from '../features/login/unauthorized.page';
import { ClaimsPage } from '../features/claim/claims.page';
import { MasterPage } from '../features/master/master.page';
import { BuildingsPage } from '../features/building/buildings.page';
import { ObservationMasterPage } from '../features/master/observation-master.page';
import { ObservationMasterOptionsPage } from '../features/master/observation-master-options.page';
import { ObservationsPage } from '../features/reports/observation/observations.page';
import { RolePage } from '../features/user-management/role.page';
import { UsersPage } from '../features/user-management/user/users.page';
import { PermissionName } from '../models/user-security/permission.name';
import { BuildingPage } from '../features/building/building.page';
import { boolean } from 'yup';
import { PermissionCheckType } from '../models/user-security/permission-check-type';
import { validateRequiredPermissions } from '../utils/permission.manager';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import InspectionReport from '../components/inspection-report.component';

export const RouterView = () => {
    const { currentPermissions } = useCurrentUser();
    return (
        // <Routes>
        //     <GuardedRoute
        //         path="/users"
        //         component={<UsersPage />}
        //         requiredPrermission={PermissionName.ViewUser}
        //     />

        //     <GuardedRoute
        //         path="/roles"
        //         component={<RolePage />}
        //         requiredPrermission={PermissionName.ViewRole}
        //     />
        //     <GuardedRoute
        //         path="/buildings/:claimGuid"
        //         component={<BuildingsPage/>}
        //         requiredPrermission={PermissionName.ViewClaimDetails}
        //     />
        //     <GuardedRoute
        //         path="/buildingdetails/:buildingID"
        //         component={<BuildingPage/>}
        //         requiredPrermission={PermissionName.ViewBuildingImage}
        //     />
        //     <GuardedRoute
        //         path="/reports/observation/:claimGuid"
        //         component={<ObservationsPage  />}
        //         requiredPrermission={PermissionName.ViewClaimDetails}
        //     />
        //     <GuardedRoute
        //         path="/window-master"
        //         component={<MasterPage masterType="window" />}
        //         /*                 masterType="window" */
        //         requiredPrermission={PermissionName.ViewMaster}
        //     />
        //     <GuardedRoute
        //         path="/door-master"
        //         component={<MasterPage masterType="door" />}
        //         /* masterType="door" */
        //         requiredPrermission={PermissionName.ViewMaster}
        //     />
        //     <GuardedRoute
        //         path="/railing-master"
        //         component={<MasterPage masterType="railing" />}
        //         // masterType="railing"/*  */
        //         requiredPrermission={PermissionName.ViewMaster}
        //     />
        //     <GuardedRoute
        //         path="/observation-master"
        //         component={<ObservationMasterPage masterType="" />}
        //         requiredPrermission={PermissionName.ViewMaster}
        //     />
        //     <GuardedRoute
        //         path="/observation-master-options/:observationID"
        //         component={<ObservationMasterOptionsPage />}
        //         requiredPrermission={PermissionName.ViewMaster}
        //     />
        //     <GuardedRoute
        //         path="/"
        //         component={<ClaimsPage />}
        //         requiredPrermission={PermissionName.ViewClaim}
        //     />
        // </Routes>

        <Routes>
            <Route
                path="/users"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewUser) ? (
                        <UsersPage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewUser}
            />

            <Route
                path="/roles"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewRole) ? (
                        <RolePage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewRole}
            />
            {/* <Route
                path="/inspectionReport"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewRole) ? (
                        <RolePage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewRole}
            /> */}
            <Route
                path="/buildings/:claimGuid"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewClaimDetails) ? (
                        <BuildingsPage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewClaimDetails}
            />
            <Route
                path="/buildingdetails/:buildingID"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewBuildingImage) ? (
                        <BuildingPage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewBuildingImage}
            />
            <Route
                path="/reports/observation/:claimGuid"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewClaimDetails) ? (
                        <ObservationsPage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewClaimDetails}
            />
            <Route
                path="/window-master"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                        <MasterPage masterType="window" />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            /*                 masterType="window" */
            // requiredPrermission={PermissionName.ViewMaster}
            />
            <Route
                path="/door-master"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                        <MasterPage masterType="door" />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            /* masterType="door" */
            // requiredPrermission={PermissionName.ViewMaster}
            />
            <Route
                path="/railing-master"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                        <MasterPage masterType="railing" />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // masterType="railing"/*  */
            // requiredPrermission={PermissionName.ViewMaster}
            />
            <Route
                path="/observation-master"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                        <ObservationMasterPage masterType="" />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewMaster}
            />
            <Route
                path="/observation-master-options/:observationID"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                        <ObservationMasterOptionsPage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewMaster}
            />
            <Route
                path="/"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewClaim) ? (
                        <ClaimsPage />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            // requiredPrermission={PermissionName.ViewClaim}
            />
            <Route
                path="/inspectionReport"
                element={
                    checkPermission(currentPermissions, PermissionName.ViewClaim) ? (
                        <InspectionReport />
                    ) : (
                        <UnauthorizedPage />
                    )
                }
            ></Route>
        </Routes>
    );

    function checkPermission(
        currentPermissions: PermissionName[],
        requiredPermission: PermissionName | PermissionName[],
    ): boolean {
        let isPermitted: boolean;
        console.log(requiredPermission);

        isPermitted =
            requiredPermission &&
            validateRequiredPermissions(
                requiredPermission,
                PermissionCheckType.All,
                currentPermissions,
            );
        return isPermitted;
    }
};
