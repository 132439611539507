import { chain, find, first, isEmpty, isNil, map } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Building } from '../../../models/claim/building';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { ObservationType } from '../../../models/masters/observation-type';
import { CopyDoorObservationsComponent } from './copy-door-observations.component';
import { CopyRailingObservationsComponent } from './copy-railing-observations.component';
import { CopyWindowObservationsComponent } from './copy-window-observations.component';
import { CopyBuildingObservationsComponent } from './copy-building-observations.component';
import { CopyFloorObservationsComponent } from './copy-floor-observation.component';
import { CopyUnitObservationsComponent } from './copy-unit-observations.component';
import { CopyRoomObservationsComponent } from './copy-room-observations.component';

interface CopyObservationComponentProps {
    building: Building;
    buildinglist?: Building[];
    observationType: ObservationType;
    onCopyObservation: (observation: ClaimObservation) => any;
    isInterior?: boolean;
}

export const CopyObservationComponent = ({
    building,
    observationType,
    buildinglist,
    onCopyObservation,
    isInterior = false,
}: CopyObservationComponentProps) => {
    const [floor, setSelectedFloor] = useState<{
        label: string;
        value: number;
    }>();
    const [unit, setSelectedUnit] = useState<{
        label: string;
        value: number;
    }>();
    const [room, setSelectedRoom] = useState<{
        label: string;
        value: number;
    }>();
    const [buildingstate, setbuildingstate] = useState<{
        label: string;
        value: number;
    }>();

    useEffect(() => {


        if (observationType !== ObservationType.Building) {
            if (!isEmpty(building?.floors)) {
                const firstFloor = !isEmpty(building?.floors) ? building?.floors[0] : undefined;
                const firstUnit =
                    !isNil(firstFloor) && !isEmpty(firstFloor?.units)
                        ? firstFloor?.units[0]
                        : undefined;
                const firstRoom =
                    !isNil(firstUnit) && !isEmpty(firstUnit?.rooms)
                        ? firstUnit?.rooms[0]
                        : undefined;

                if (firstFloor)
                    setSelectedFloor({ value: firstFloor.floorID, label: firstFloor.floorName });
                if (firstUnit)
                    setSelectedUnit({ value: firstUnit.unitID, label: firstUnit.unitName });
                if (firstRoom)
                    setSelectedRoom({ value: firstRoom.roomID, label: firstRoom.roomName });
            }
        } else {
            if (!isEmpty(buildinglist)) {
                setbuildingstate({
                    label: buildinglist[0].buildingName,
                    value: buildinglist[0].buildingID,
                });
            }
        }
    }, []);

    const selectedFloor = find(building?.floors, (f) => f.floorID === floor?.value);
    const selectedUnit = find(selectedFloor?.units, (u) => u.unitID === unit?.value);
    const selectedRoom = find(selectedUnit?.rooms, (r) => r.roomID === room?.value);
    // const selectedbuilding = find(buildinglist, (b) => b.buildingID === buildingstate.value);
    const floorOptions = map(building?.floors, (f) => {
        return {
            label: f.floorName,
            value: f.floorID,
        };
    });
    const buildingoptions = map(buildinglist, (b) => {
        return {
            label: b.buildingName,
            value: b.buildingID,
        };
    });

    const unitOptions = map(selectedFloor?.units, (u) => {
        return {
            label: u.unitName,
            value: u.unitID,
        };
    });

    const roomOptions = map(selectedUnit?.rooms, (r) => {
        return {
            label: r.roomName,
            value: r.roomID,
        };
    });

    const handleFloorSelectionChange = (floorSelected: any) => {
        if (floorSelected.value !== floor?.value) {
            setSelectedFloor(floorSelected);
            setSelectedUnit(null);
            setSelectedRoom(null);
        }
    };

    const handleBuildingSelectionChange = (buildingSelected: any) => {
        if (buildingSelected.value !== buildingstate?.value) {
            setbuildingstate(buildingSelected);
        }
    };

    const handleUnitSelectionChange = (unitSelected: any) => {
        if (unitSelected.value !== unit?.value) {
            setSelectedUnit(unitSelected);
            setSelectedRoom(null);
        }
    };

    const handleRoomSelectionChange = (unitSelected: any) => {
        setSelectedRoom(unitSelected);
    };

    return observationType === ObservationType.Window ||
        observationType === ObservationType.Railing ||
        observationType === ObservationType.Door ? (
        <div>
            <label>Floor</label>
            <Select value={floor} options={floorOptions} onChange={handleFloorSelectionChange} />
            <label>Unit</label>
            <Select value={unit} options={unitOptions} onChange={handleUnitSelectionChange} />
            <label>Room</label>
            <Select value={room} options={roomOptions} onChange={handleRoomSelectionChange} />
            {observationType === ObservationType.Window && (
                <CopyWindowObservationsComponent
                    roomId={room?.value}
                    onCopyObservation={onCopyObservation}
                />
            )}
            {observationType === ObservationType.Door && (
                <CopyDoorObservationsComponent
                    roomId={room?.value}
                    onCopyObservation={onCopyObservation}
                />
            )}
            {observationType === ObservationType.Railing && (
                <CopyRailingObservationsComponent
                    roomId={room?.value}
                    onCopyObservation={onCopyObservation}
                />
            )}
        </div>
    ) : (
        <div>
            {observationType === ObservationType.Building && (
                <CopyBuildingObservationsComponent
                    isInterior={isInterior}
                    buildingsList={buildinglist}
                    buildingID={buildingstate?.value}
                    onCopyObservation={onCopyObservation}
                />
            )}
            {observationType === ObservationType.Floor && (
                <CopyFloorObservationsComponent
                    buildingList={buildinglist}
                    onCopyObservation={onCopyObservation}
                />
            )}
            {observationType === ObservationType.Unit && (
                <CopyUnitObservationsComponent
                    onCopyObservation={onCopyObservation}
                    buildingList={buildinglist}
                />
            )}
            {observationType === ObservationType.Room && (
                <CopyRoomObservationsComponent
                    onCopyObservation={onCopyObservation}
                    buildingList={buildinglist}
                />
            )}
        </div>
    );
};
