import React from 'react';

const DeleteGreyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.017" height="15.45" viewBox="0 0 12.017 15.45">
      <path d="M8.358,18.234a1.722,1.722,0,0,0,1.717,1.717h6.867a1.722,1.722,0,0,0,1.717-1.717V7.933H8.358ZM19.517,5.358h-3L15.654,4.5H11.363l-.858.858h-3V7.075H19.517Z" transform="translate(-7.5 -4.5)" fill="#8f8f8f"/>
    </svg>
  );
};

export default DeleteGreyIcon;
