import { Building } from '../../../models/claim/building';
import { Room } from '../../../models/claim/room';
import {
    PanelBar,
    PanelBarItem,
    PanelBarItemProps,
    PanelBarUtils,
} from '@progress/kendo-react-layout';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { UnitRailing } from '../../../models/claim/unit-railing';
import videoimg from '../../../images/file-video';
import photolink from '../../../images/image-link';
import imageimg from '../../../images/file-image';
import EyeIcon from '../../../images/eye';
import { useEffect, useRef, useState } from 'react';
import { EditRailing } from './edit-railing.component';
import { EntityType } from '../../../models/claim/entity-type';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../../utils/array.util';
import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { EditObservation } from '../observation/edit-observation.component';
import { ObservationType } from '../../../models/masters/observation-type';
import { ObservationListComponent } from '../observation/observation-list.component';
import { ImageListModelDialog } from '../../image/image-list-model-dialog.component';
import editImg from '../../../images/edit-gray';
import DeleteGreyIcon from '../../../images/delete-gray';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { PermissionName } from '../../../models/user-security/permission.name';
import { LinkImageModelDialog } from '../../building-image/link-image-model-dialog.component';
import { isEmpty, isNil } from 'lodash';
import ImageLink from '../../../images/image-link';
import FileImage from '../../../images/file-image';
import FileVideoIcon from '../../../images/file-video';
import EditGrayIcon from '../../../images/edit-gray';
import FileDialogButton from '../../../components/add-image.button.component';
import {
    ImgFile,
    deleteFile,
    editComments,
    fetchFile,
    uploadImages,
} from '../../../utils/uploadfile.util';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { fetchFiles } from '../../../api-client.ts/fileapi';
import { UploadedFile } from '../../../redux/reducers/filereducer';
import ImageUploader from '../../../components/add-images.component';
import { Image } from 'react-grid-gallery';
import { Attribute } from '../../../models/claim/attribute';
import DeleteIcon from '../../../images/delete';
import EditIcon from '../../../images/edit';

interface RailingsComponentProps {
    building: Building;
    room: Room;
}

export const RailingsComponent = ({ building, room }: RailingsComponentProps) => {
    const { isLoading, result, isError } = useLoadData<Room>(`rooms?roomid=${room?.roomID}`);
    const [roomData, setRoomData] = useState<Room>();
    const [imageListData, setImageListData] = useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    const [data, setEditRailing] = useState<{ building: Building; editRailing: UnitRailing }>();
    const [deleteRailingData, setDeleteRailingData] = useState<DeletePostDataInfo>();
    const [newObservation, setNewObservation] = useState<{
        newObservation: ClaimObservation;
        copyFrom?: boolean;
        updateOption: ClaimObservationOption;
    }>();
    const [deleteObservation, setDeleteObservation] = useState<DeletePostDataInfo>();
    const [linkImageData, setLinkImageData] = useState<{
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }>();
    const [photos, setPhoto] = useState<Image[]>();
    const observationtype = useRef<{ entityType: ObservationType; entityID: number }>({
        entityType: ObservationType.Railing,
        entityID: 0,
    });
    const [imgList, setImgList] = useState<ImgFile[]>([]);
    const [imgpreview, showImgPreview] = useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });
    const dispatch = useDispatch<AppDispatch>();
    const uploadedfileList = useRef<UploadedFile[]>([]);
    const handleAddFileToImgList = async (file: File) => {
        await uploadImages([file], {
            EntityID: observationtype.current.entityID.toString(),
            EntityType: observationtype.current.entityType,
            IsInterior: false,
            Comments: imgpreview.comment ?? '',
        }).then((value: UploadedFile) => {
            imgList.push({
                comment: imgpreview.comment!,
                file: file,
                isbaseImg: value.isBase,
                baseID: 0,
                entityID: value.entityID,
                entityType: value.entityType,
                fileID: value.fileID.toString(),
                userGuid: '',
            });
            //  alert('upload success');
            loadImages();
            showImgPreview({
                imgpreview: false,
                src: null,
            });
        });
    };
    useEffect(() => {
        loadImages();
    }, []);

    /* function to show picture of selected observation*/
    const onShowObservationPhotos = (observation: ClaimObservation) => {
        setPhoto([
            ...observation.photos.map(
                (image, index): Image => ({
                    src: image.fileCDN,
                    height: 200,
                    width: 200,
                    key: image.observationID,
                    orientation: 1,
                    caption: image.comments,
                }),
            ),
        ]);
    };

    const loadImages = async () => {
        try {
            const action = await dispatch(
                fetchFiles({
                    FileType: 'image',
                    UserID: 0,
                    EntityType: observationtype.current.entityType,
                    EntityID: observationtype.current.entityID,
                }),
            );

            if (action.payload) {
                let uploadedFileArray = action.payload as UploadedFile[];

                uploadedfileList.current = uploadedFileArray;

                let files = await Promise.all(
                    uploadedFileArray.map(async (e) => {
                        return fetchFile(
                            e.fileCDN,
                            e.comments,
                            e.entityID.toString(),
                            observationtype.current.entityType,
                            e.isBasePhoto,
                            e.fileID.toString(),
                            e.basePhotoID,
                        );
                    }),
                );

                setImgList(files);
            } else {
                setImgList([]);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setImgList([]);
        }
    };
    useEffect(() => {
        if (result) {
            const roomData = { ...result };
            setRoomData(roomData);
        }
    }, [result]);

    const openGallery = (data: { entityType: EntityType; entityId: number }) => {
        setImageListData(data);
    };

    const closGallery = () => {
        setImageListData(null);
    };

    const onAddRailing = () => {
        const selectedRailing: UnitRailing = {
            roomID: room.roomID,
            railingID: 0,
            railingName: '',
            direction: '',
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditRailing({ building: null, editRailing: selectedRailing });
    };

    const onEditRailing = (selectedRailing: UnitRailing) => {
        setEditRailing({ building: null, editRailing: selectedRailing });
    };
    const onDeleteRailing = (selectedRailing: UnitRailing) => {
        setDeleteRailingData({
            data: selectedRailing,
            route: `railings/delete/${selectedRailing.railingID}`,
        });
        setImgList([]);
    };

    const onAddObservationClick = (railing: UnitRailing, copyFrom: boolean) => {
        const observation = new ClaimObservation();
        observation.observationID = 0;
        observation.entityType = ObservationType.Railing;
        observation.entityID = railing.railingID;
        setNewObservation({
            newObservation: observation,
            copyFrom: copyFrom,
            updateOption: null,
        });
    };
    const onEditObservationClick = (
        observation: ClaimObservation,
        updateOption: ClaimObservationOption,
    ) => {
        setNewObservation({
            newObservation: observation,
            updateOption: updateOption,
        });
    };
    const onDeleteObservationClick = (claimObservation: ClaimObservation) => {
        setDeleteObservation({
            data: claimObservation,
            route: `ClaimObservation/${claimObservation.observationID}`,
        });
    };
    const handleDeleteRailingComplete = (status: DeleteStatus, data: UnitRailing) => {
        if (status == DeleteStatus.Completed) {
            roomData.railings = deleteArrayItem(roomData.railings, data, 'railingID');
            setRoomData({ ...roomData });
            setImgList([]);
        }
        setDeleteRailingData(null);
    };

    const handleSaveRailingComplete = (status: SubmitStatus, data: UnitRailing) => {
        if (status === SubmitStatus.Completed) {
            const railing = roomData.railings?.find((r) => r.railingID === data.railingID);
            roomData.railings = addOrUpdateArrayItem(
                roomData.railings,
                { ...railing, ...data },
                'railingID',
            );

            setRoomData({ ...roomData });
            setEditRailing(null);
            setPhoto([]);
            setImgList([]);
            loadImages();
        } else {
            setEditRailing(null);
        }
    };

    const handleSaveObservationComplete = (status: SubmitStatus, data: ClaimObservation) => {
        if (status === SubmitStatus.Completed) {
            const currentRailing = roomData.railings.find((p) => {
                return p.railingID === data.entityID;
            });
            currentRailing.observations = addOrUpdateArrayItem(
                currentRailing.observations,
                data,
                'observationID',
            );
            setRoomData({ ...roomData });
            setNewObservation(null);
        } else {
            setNewObservation(null);
        }
        setPhoto([]);
        loadImages();
    };

    const handleDeleteObservationComplete = (status: DeleteStatus, data: ClaimObservation) => {
        if (status == DeleteStatus.Completed) {
            const railing = roomData.railings.find((d) => d.railingID === data.entityID);
            railing.observations = deleteArrayItem(railing.observations, data, 'observationID');
            setRoomData({ ...roomData });
        }
        setDeleteObservation(null);
        setPhoto([]);
    };

    const handleLinkWinodwComplete = () => {
        setLinkImageData(null);
    };

    const linkWindowImage = (data: {
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }) => {
        setLinkImageData(data);
    };
    const viewAttributes = (attributes: Attribute[]) => {
        const combinedString = attributes.map((attribute) => attribute.attributeValue).join(',');
        if (combinedString.length > 50) {
            const trimmedString = combinedString.substring(0, 51) + '...';
            return trimmedString;
        } else {
            return combinedString;
        }
    };

    const railingData = () => {
        if (roomData) {
            const panelItems: PanelBarItemProps[] = roomData.railings.map<PanelBarItemProps>(
                (item): PanelBarItemProps => {
                    return {
                        id: item.railingID.toString(),
                        title: (
                            <div className="structureList-grid" key={item.railingID}>
                                <div>
                                    <h5 className="text-normal">{item.railingName}</h5>
                                </div>
                                <div className="">
                                    <h6 className="text-normal">{item.direction}</h6>
                                </div>
                                <div className="options-list">
                                    {viewAttributes(item.attributes)}
                                </div>
                                <div>
                                    <button
                                        className="tag-buttons"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            linkWindowImage({
                                                entityType: EntityType.Railing,
                                                entityId: item.railingID,
                                                entityName: item.railingName,
                                                direction: item.direction,
                                            });
                                        }}
                                    >
                                        {item.imageCordinateID > 0 ? <EyeIcon /> : <ImageLink />}{' '}
                                        {/* <img src={item.imageCordinateID > 0 ? eyeImg : photolink} /> */}
                                    </button>
                                </div>
                                <div className="d-none">
                                    <FileVideoIcon /> {/* <img src={videoimg} /> */}
                                    <span className="tagtop">0</span>
                                </div>
                                <div>
                                    <button
                                        className="tag-buttons"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openGallery({
                                                entityType: EntityType.Railing,
                                                entityId: item.railingID,
                                            });
                                        }}
                                    >
                                        <FileImage></FileImage> {/* <img src={imageimg} /> */}
                                        <span className="tagtop">{item.images}</span>
                                    </button>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.EditRailing}>
                                        <button
                                            className="tag-buttons"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onEditRailing(item);
                                            }}
                                        >
                                            <EditIcon></EditIcon>{' '}
                                            {/* <img src={editImg} /> */}
                                        </button>
                                    </GuardedComponent>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.DeleteRailing}>
                                        <button
                                            className="tag-buttons"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteRailing(item);
                                            }}
                                        >
                                            <DeleteIcon></DeleteIcon>{' '}
                                            {/* <img src={deleteImg} /> */}
                                        </button>
                                    </GuardedComponent>
                                </div>
                            </div>
                        ),
                        children: [
                            /*  {
                                title: ( */
                            <div className="me-3" key={item.railingID + 'buttons'}>
                                <GuardedComponent permission={PermissionName.ViewObservation}>
                                    <ObservationListComponent
                                        handleCancel={() => { loadImages(); }}
                                        handleDelete={async (img: ImgFile) => {
                                            const isdeleted: boolean = await deleteFile(img);
                                            if (isdeleted) {
                                                setPhoto([]);
                                                await loadImages().then(() => {
                                                    if (result.railings.length > 0) {
                                                        result.railings.forEach((w) => {
                                                            if (w.observations.length > 0) {
                                                                w.observations.forEach((o) => {
                                                                    console.log(o);

                                                                    if (o.photos.length > 0) {
                                                                        let photo = o.photos.find(
                                                                            (p) =>
                                                                                p.fileId ===
                                                                                parseInt(
                                                                                    img.fileID,
                                                                                ),
                                                                        );
                                                                        if (photo) {
                                                                            let filteredPhotos =
                                                                                o.photos.filter(
                                                                                    (p) =>
                                                                                        p.fileId !==
                                                                                        parseInt(
                                                                                            img.fileID,
                                                                                        ),
                                                                                );
                                                                            filteredPhotos.length >
                                                                                0
                                                                                ? (o.photos = [
                                                                                    ...filteredPhotos,
                                                                                ])
                                                                                : (o.photos = []);
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        }}
                                        handleSave={async (file: ImgFile) => {
                                            const isedited = await editComments({
                                                fileId: file.fileID!,
                                                comments: file.comment,
                                                isBaseImg: file.isbaseImg,
                                                baseID: file.baseID,
                                                entityID: file.entityID,
                                                entityType: file.entityType,
                                                fileID: file.fileID,
                                                userGuid: '',
                                            });
                                            if (isedited) {
                                                setPhoto([]);
                                                await loadImages().then(() => {
                                                    if (result.railings.length > 0) {
                                                        result.railings.forEach((w) => {
                                                            if (w.observations.length > 0) {
                                                                w.observations.forEach((o) => {
                                                                    console.log(o);

                                                                    if (o.photos.length > 0) {
                                                                        let photo = o.photos.find(
                                                                            (p) =>
                                                                                p.fileId ===
                                                                                parseInt(
                                                                                    file.fileID,
                                                                                ),
                                                                        );
                                                                        if (photo) {
                                                                            o.photos.find(
                                                                                (p) =>
                                                                                    p.fileId ===
                                                                                    parseInt(
                                                                                        file.fileID,
                                                                                    ),
                                                                            ).comments =
                                                                                file.comment;
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        }}
                                        addphoto={(e: any) => {
                                            showImgPreview({
                                                imgpreview: true,
                                                src: e.target.files[0],
                                            });
                                            observationtype.current = {
                                                entityID: item.railingID,
                                                entityType: ObservationType.Railing,
                                            };
                                        }}
                                        onAddNewObservationClick={() =>
                                            onAddObservationClick(item, false)
                                        }
                                        oncopyobservation={() => onAddObservationClick(item, true)}
                                        onShowObservationPhotos={onShowObservationPhotos}
                                        photos={photos}
                                        observations={item?.observations}
                                        onEditObservation={onEditObservationClick}
                                        onDeleteObservation={onDeleteObservationClick}
                                        onOpenGallery={openGallery}
                                        imgList={imgList}
                                    />
                                    {imgpreview.imgpreview ? (
                                        <ImageUploader
                                            onAddImg={(comment: string) => {
                                                imgpreview.comment = comment;
                                                handleAddFileToImgList(imgpreview.src);
                                            }}
                                            onclose={() => {
                                                showImgPreview({
                                                    imgpreview: false,
                                                    src: null,
                                                });
                                            }}
                                            showModal={imgpreview.imgpreview}
                                            img={imgpreview.src}
                                        />
                                    ) : null}
                                </GuardedComponent>
                            </div>,
                            /*   ),
                            }, */
                        ],
                    };
                },
            );
            return panelItems;
        }
    };

    if (isError) {
        return <div>Error loading railings</div>;
    }

    return (
        <div>
            <GuardedComponent permission={PermissionName.AddRailing}>
                <div className='stack_title1 mb-2 mt-2 d-flex justify-content-end'>
                    <button className="ms-auto btn btn-outline-primary btn-sm" onClick={onAddRailing}>
                        Add Railing
                    </button>
                </div>
            </GuardedComponent>

            <LoadingIndicator isLoading={isLoading} />
            {roomData && (
                <PanelBar
                    expandMode="single"
                    onSelect={(e) => {
                        console.log(e.target.key);
                        let railingID: string =
                            e.target.key.split('$')[e.target.key.split('$').length - 1];
                        console.log(railingID);

                        observationtype.current = {
                            entityID: parseInt(railingID),
                            entityType: ObservationType.Railing,
                        };
                        setImgList([]);

                        setPhoto([]);
                        loadImages();
                    }}
                >
                    {' '}
                    {railingData().map((e) => {
                        return (
                            <PanelBarItem key={e.id} title={e.title}>
                                {e.children}
                            </PanelBarItem>
                        );
                    })}
                </PanelBar>
            )}
            {imageListData && <ImageListModelDialog data={imageListData} onCancel={closGallery} />}
            {data && <EditRailing data={{ building: building, editRailing: data.editRailing }} onClose={handleSaveRailingComplete} />}
            {newObservation && (
                <EditObservation
                    observationType={ObservationType.Railing}
                    building={building}
                    data={newObservation}
                    imgList={imgList}
                    onClose={handleSaveObservationComplete}
                    uploadedFiles={uploadedfileList.current}
                />
            )}
            {deleteRailingData && (
                <DeleteModelDialog
                    title="Delete Railing"
                    deleteData={deleteRailingData}
                    onClose={handleDeleteRailingComplete}
                >
                    <div>
                        Are you sure you want to delete Railing -{' '}
                        {deleteRailingData.data.railingName}?
                    </div>
                </DeleteModelDialog>
            )}
            {deleteObservation && (
                <DeleteModelDialog
                    title="Delete Observation"
                    deleteData={deleteObservation}
                    onClose={handleDeleteObservationComplete}
                >
                    <div>
                        Are you sure you want to delete Observation -{' '}
                        {deleteObservation.data.observation}?
                    </div>
                </DeleteModelDialog>
            )}
            <LinkImageModelDialog
                buildingId={building.buildingID}
                linkImageData={linkImageData}
                title={'Link Window'}
                onClose={handleLinkWinodwComplete}
            />
        </div>
    );
};
