import { Link } from 'react-router-dom';
import Logo from '../images/logo-keystone';
import { MenuComponent } from './menu.component';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import './header.scss';
import { LogoutComponent } from '../features/login/logout.component';

export const HeaderComponent = () => {
    const { fullName } = useCurrentUser();

    return (
        <div className="header">
            <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <Logo></Logo> {/* <img src={Logo} alt='logo' className="logo" /> */}
                    </Link>
                </div>
                <div className="navbar-collapse justify-content-between">
                    <MenuComponent />
                </div>
                <div className="navbar-collapse collapse justify-content-between">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item user-name text-nowrap">{fullName}</li>
                        <li className="nav-item pt-1">
                            <LogoutComponent />
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};
