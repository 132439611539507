import { chain, map, some } from 'lodash';
import { useEffect, useState } from 'react';
import { CheckboxField } from '../../components/form/checkbox-field.component';
import { InputField } from '../../components/form/input.feild.component';
import { FormikModelDialog, SubmitStatus } from '../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { UserPermission } from '../../models/user-security/user-permission';
import { UserRole } from '../../models/user-security/user-role';

interface EditMasterProps {
    role?: UserRole;
    onClose?: (status: SubmitStatus, data: any) => any;
}

interface InitValue {
    roleName: string;
    roleDescription: string;
    permissionList: UserPermission[];
}

export const EditRole = ({ role, onClose }: EditMasterProps) => {
    const { isLoading, result, isError } = useLoadData<UserPermission[]>(`roles/permissions`);

    const title = role.roleId === 0 ? 'Add Role' : 'Edit Role';

    const initialValue = (): InitValue => {
        if (result) {
            return {
                roleName: role.roleName,
                roleDescription: role.roleDescriprion,
                permissionList: map(result, (p, i) => {
                    return {
                        permissionID: p.permissionID,
                        permissionName: p.permissionName,
                        permissionDescription: p.permissionDescription,
                        isSelected: some(
                            role.permissions,
                            (rp) => rp.permissionID === p.permissionID,
                        ),
                    };
                }),
            };
        }
    };

    const permissionGroups = chain(result)
        .map((p) => p.group)
        .uniq()
        .value();

    const postDataFormatter = (data: InitValue): UserRole => {
        const submitData = {
            roleId: role.roleId,
            roleName: data.roleName,
            roleDescriprion: data.roleDescription,
            isActive: true,
            permissions: chain(data.permissionList)
                .filter((p) => p.isSelected)
                .map((p) => {
                    return {
                        permissionID: p.permissionID,
                        permissionName: p.permissionName,
                    };
                })
                .value(),
        };
        return submitData;
    };

    return (
        <FormikModelDialog
            title={title}
            initialData={initialValue()}
            show={!!role}
            onClose={onClose}
            postDataFormatter={postDataFormatter}
            postRoute="roles"
        >
            <>
                <LoadingIndicator isLoading={isLoading} />
                <div>
                    <InputField label="Role Name" name="roleName" />
                </div>
                <div>Permissions</div>
                {isError && 'Error loading permission list'}
                {result && (
                    <div className="permissions-list">
                        {map(permissionGroups, (groupName) => (
                            <div className="permissions-group">
                                <strong>{groupName}</strong>
                                <div className="permissions">
                                    {map(
                                        result,
                                        (p, i) =>
                                            p.group === groupName && (
                                                <CheckboxField
                                                    name={`permissionList[${i}].isSelected`}
                                                    label={p.permissionDescription}
                                                />
                                            ),
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </>
        </FormikModelDialog>
    );
};
