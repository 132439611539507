import { useState } from 'react';
import * as Yup from 'yup';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../../components/delete-model-dialog.component';
import { InputField } from '../../../components/form/input.feild.component';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { Building } from '../../../models/claim/building';
import { Floor } from '../../../models/claim/floor';
import { EditDialogHeader } from '../edit-dialog-header.component';
import NumericField from '../../../components/form/numeric-input-field.component';

interface EditFloorProps {
    data?: { currentBuilding: Building; floor: Floor };
    onDeleted?: (floor: Floor) => any;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditFloor = ({
    data: { currentBuilding, floor },
    onDeleted,
    onClose,
}: EditFloorProps) => {
    const [deleteFloorData, setDeleteFloor] = useState<DeletePostDataInfo>();

    const title = floor.floorID === 0 ? 'Add Floor' : 'Edit Floor';
    const validationSchema = Yup.object().shape({
        floorName: Yup.string().required('Required'),
        floorNumber: Yup.string().required('Required'),
    });
    const initialData: Floor = {
        floorID: floor.floorID,
        buildingID: floor.buildingID,
        floorName: floor.floorName,
        floorNumber: floor.floorNumber,
    };

    const onDeleteFloorClick = () => {
        const { floorID } = floor;
        setDeleteFloor({
            data: { ...floor },
            route: `floors/delete/${floorID}`,
        });
    };

    const onDeleteComplete = (status: DeleteStatus, floor: Floor) => {
        if (status == DeleteStatus.Completed && onDeleted) {
            onDeleted(floor);
        }
        setDeleteFloor(null);
    };

    if (deleteFloorData) {
        return (
            <DeleteModelDialog
                title="Delete Floor"
                deleteData={deleteFloorData}
                onClose={onDeleteComplete}
            >
                <div>Are you sure you want to delete floor - {deleteFloorData.data.floorName}?</div>
            </DeleteModelDialog>
        );
    }

    return (
        <FormikModelDialog
            width="40%"
            title={<EditDialogHeader title={title} buildingName={currentBuilding.buildingName} />}
            customButtons={
                floor.floorID > 0 && <button className="btn btn-secondary btn-sm" onClick={onDeleteFloorClick}>Delete</button>
            }
            initialData={initialData}
            validationSchema={validationSchema}
            show={!!floor}
            onClose={onClose}
            postRoute="floors"
        >
            <>
                <InputField label="Floor Name" name="floorName" />
                <NumericField name="floorNumber" label="Floor Number" />
            </>
        </FormikModelDialog>
    );
};
