import { DeleteModelDialog, DeleteStatus } from '../../components/delete-model-dialog.component';
import { Building } from '../../models/claim/building';
import { EntityType } from '../../models/claim/entity-type';

interface DeleteWindowDoorWrapperProps {
    building: Building;
    entity: any;
    onClose: (status: DeleteStatus, data: any) => any;
}

export const DeleteWindowDoorWrapperComponent = ({
    entity,
    onClose,
}: DeleteWindowDoorWrapperProps) => {
    const getEntityType = () => {
        return entity.windowID ? EntityType.Window : EntityType.Door;
    };

    const getDeleteData = () => {
        return {
            data: entity,
            route:
                getEntityType() == EntityType.Window
                    ? `windows/delete/${entity.entityId}`
                    : `doors/delete/${entity.entityId}`,
        };
    };

    return (
        <DeleteModelDialog
            title={`Delete ${getEntityType()}`}
            deleteData={getDeleteData()}
            onClose={onClose}
        >
            <div>Are you sure you want to delete {entity.entityName}?</div>
        </DeleteModelDialog>
    );
};
