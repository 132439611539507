import * as Yup from 'yup';
import { DatePickerField } from '../../components/form/datepicker-field.component';
import { FormikModelDialog, SubmitStatus } from '../../components/formik-model-dialog.component';
import { Claim } from '../../models/claim/claim';
import './claims.scss';
import { InputField } from '../../components/form/input.feild.component';
import { AddressField } from '../../components/form/address-field.component';

interface EditClaimProps {
    data?: Claim;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditClaim = ({ data, onClose }: EditClaimProps) => {
    const title = data.claimID === 0 ? 'Add Claim' : 'Edit Claim';

    const validationSchema = Yup.object().shape({
        claimNumber: Yup.string().required('Required'),
        policyNumber: Yup.string().required('Required'),
        keystoneNumber: Yup.string().required('Required'),
        dateOfLoss: Yup.string().required('Required'),
        client: Yup.string().required('Required'),
        buildingComplexName: Yup.string().required('Required'),
        buildingComplexAddress: Yup.string().required('Required'),
    });

    const initialData: Claim = {
        claimID: data?.claimID ? data?.claimID : 0,
        client: data?.client,
        claimNumber: data?.claimNumber,
        keystoneNumber: data?.keystoneNumber,
        policyNumber: data?.policyNumber,
        dateOfLoss: data?.dateOfLoss ? new Date(data?.dateOfLoss) : undefined,
        buildingComplexName: data?.buildingComplexName,
        buildingComplexAddress: data?.buildingComplexAddress,
        latitude: data?.latitude,
        longitude: data?.longitude,
    };

    return (
        <FormikModelDialog
            width="60%"
            title={title}
            initialData={initialData}
            validationSchema={validationSchema}
            show={!!data}
            onClose={onClose}
            postRoute="claims/claim"
        >
            <>
                <div className="row labelfix">
                    <div className="col-6">
                        <InputField label="Claim Number" name="claimNumber" />
                    </div>
                    <div className="col-6">
                        <InputField label="Policy Number" name="policyNumber" />
                    </div>
                    <div className="col-6">
                        <InputField label="Keystone Number" name="keystoneNumber" />
                    </div>
                    <div className="col-6">
                        <InputField label="Insured" name="client" />
                    </div>
                    <div className="col-6 datepickerfix">
                        <DatePickerField name="dateOfLoss" label="Date of loss" />
                    </div>
                    <div className="col-6">
                        <InputField
                            label="Building Complex Name (Project Name)"
                            name="buildingComplexName"
                        />
                    </div>
                    <div className="col-6 project-address-filed">
                        <AddressField
                            label="Project Address"
                            name="buildingComplexAddress"
                            value={initialData.buildingComplexAddress}
                        />
                    </div>
                </div>
            </>
        </FormikModelDialog>
    );
};
