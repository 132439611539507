import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { User } from '../../../models/user-security/user';
import * as Yup from 'yup';
import { InputField } from '../../../components/form/input.feild.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { UserRole } from '../../../models/user-security/user-role';
import { CheckboxField } from '../../../components/form/checkbox-field.component';
import { map, some } from 'lodash';

interface EditUserProps {
    data?: User;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditUser = ({ data, onClose }: EditUserProps) => {
    const title = data.userGUID ? 'Edit User' : 'Add User';
    const { isLoading, result, isError } = useLoadData<UserRole[]>(`roles`);

    const validationSchema = Yup.object().shape({
        userName: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        emailAddress: Yup.string().required('Required'),
        phone: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords are not same'),
    });

    const initialValue = (): User => {
        if (result) {
            return {
                userName: data?.userName,
                fullName: data?.fullName,
                emailAddress: data?.emailAddress,
                isAuthenticated: false,
                userGUID: '',
                currentPermissions: [],
                firstName: data.firstName,
                lastName: data.lastName,
                companyName: data.companyName,
                phone: data.phone,
                userRoles: map(result, (p, i) => {
                    return {
                        roleId: p.roleId,
                        roleName: p.roleName,
                        isSelected: some(data.userRoles, (r) => r.roleId === p.roleId),
                    };
                }),
            };
        }
    };

    const postDataFormatter = (data: any): User => {
        data.userRoles = data.userRoles.filter((p: any) => p.isSelected);
        return data;
    };

    if (isError) {
        return <div>Error occured while loading data</div>;
    }

    return (
        <FormikModelDialog
            width='50%'
            title={title}
            initialData={initialValue()}
            validationSchema={validationSchema}
            show={!!data}
            onClose={onClose}
            postDataFormatter={postDataFormatter}
            postRoute="users/user"
        >
            <>
                <div className="row">
                    <div className="col-6">
                        <InputField label="User Name" name="userName" />
                    </div>
                    <div className="col-6">
                        <InputField label="First Name" name="firstName" />
                    </div>
                    <div className="col-6">
                        <InputField label="Last Name" name="lastName" />
                    </div>
                    <div className="col-6">
                        <InputField label="Company Name" name="companyName" />
                    </div>
                    <div className="col-6">
                        <InputField label="Email" name="emailAddress" />
                    </div>
                    <div className="col-6">
                        <InputField label="Phone" name="phone" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <InputField label="Password" name="password" type="password" />
                    </div>
                    <div className="col-6">
                        <InputField
                            label="Confirm Password"
                            name="confirmPassword"
                            type="password"
                        />
                    </div>
                </div>
                <div>Roles</div>
                {isError && 'Error loading roles list'}
                {result && (
                    <div className="row">
                        <div className="permissions">
                            {result.map((item, i) => (
                                <CheckboxField
                                    name={`userRoles[${i}].isSelected`}
                                    label={item.roleName}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </>
        </FormikModelDialog>
    );
};
