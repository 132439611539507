import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Formik } from 'formik';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { usePostData } from '../hooks/use-post-data.hook';
import {
    FormikBttonsComponent,
    SubmitActionType,
    SUBMIT_ACTION_TYPE_KEY_NAME,
} from './formik-buttons.component';
import { LoadingIndicator } from './loading-indicator.component';

interface FormikModelDialogProps {
    children: ReactNode;
    customButtons?: ReactElement<any>;
    show: boolean;
    title: string | ReactElement<any>;
    footer?: ReactElement<any>;
    initialData: any;
    validate?: any;
    validationSchema?: any;
    postRoute: string;
    onClose?: (status: SubmitStatus, data: any) => any;
    postDataFormatter?: (data: any) => any;
    showAddAnother?: boolean;
    width?: string;
}

export enum SubmitStatus {
    Completed,
    CompletedAddNew,
    Cancel,
}

export const FormikModelDialog = ({
    children,
    customButtons,
    title,
    footer,
    show,
    initialData,
    width = "95%",
    validate,
    validationSchema,
    postRoute,
    onClose,
    postDataFormatter,
    showAddAnother,
}: FormikModelDialogProps) => {
    const {
        submitData,
        response: { isSubmitting, result, error, submitActionType },
    } = usePostData<any>(postRoute, 'POST');

    useEffect(() => {
        console.log(`the initial data is${initialData}`);
        console.log(children.toString());


        if (!isSubmitting && result && !error) {
            if (submitActionType === SubmitActionType.SAVE_AND_CLOSE) {
                onClose(SubmitStatus.Completed, result);
            }
        }
    }, [result, isSubmitting, initialData]);

    const handleSubmit = async (updatedData: any) => {
        const submitActionType = updatedData[SUBMIT_ACTION_TYPE_KEY_NAME];
        delete updatedData[SUBMIT_ACTION_TYPE_KEY_NAME];
        const postData = postDataFormatter ? postDataFormatter(updatedData) : { ...updatedData };
        console.log(submitData);

        submitData({ data: postData, submitActionType });
    };

    const handleClose = () => {
        onClose(SubmitStatus.Cancel, null);
    };

    return (
        <>

            {show && (
                <Dialog title={title} onClose={handleClose} width={width}>
                    <div>
                        <Formik
                            initialValues={initialData}
                            validate={validate}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {() => (
                                <Form>
                                    <LoadingIndicator isLoading={isSubmitting} />
                                    {error && <div>Error</div>}
                                    {children}
                                    <DialogActionsBar>
                                        <FormikBttonsComponent
                                            customButtons={customButtons}
                                            showAddAnother={showAddAnother}
                                            onClose={handleClose}
                                        />
                                    </DialogActionsBar>
                                </Form>
                            )}
                        </Formik>
                        {footer}
                    </div>
                </Dialog>
            )}

        </>
    );
};
