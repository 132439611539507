import { DynamicField } from '../../../components/form/dynamic-field.component';
import { InputTextArea } from '../../../components/form/input-textarea.component';
import { Observation } from '../../../models/masters/observation';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { ObservationType } from '../../../models/masters/observation-type';
import { useEffect, useState } from 'react';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Field, useField } from 'formik';
import { MultiSelctOptionValue, MultiSelectAlign, MultiSelectField } from '../../../components/form/multi-select-field.component';
import { MasterType } from '../../../models/masters/master-type';
import { join, map } from 'lodash';
import { MasterValue } from '../../../models/masters/master-value';
import ToggleSwitch from '../../../components/toggle-switchcomponent';
import { WINDOWS_DOOR_OTHER_OPTION } from '../../../constants';

interface EditObservationOptionsFromProps {
    selectedObservation: Observation;
    observationType?: ObservationType;
}

export const EditObservationOptionsFrom = ({
    selectedObservation,
    observationType,
}: EditObservationOptionsFromProps) => {

    const { isError, isLoading, reload, result } = useLoadData<MasterType[]>('masters?type=observation');
    const [field, meta, helpers] = useField('isStormRelated');
    const [isEvent, setIsEvent] = useState<boolean>(field.value);

    useEffect(() => {
        setIsEvent(field.value);
    }, [field.value]);

    const getObservationInputType = (): InputFieldType => {
        return selectedObservation?.observationSelectType;
    };

    const getObservationOptionSelectValues = () => {
        return selectedObservation?.options?.map<any>((opt) => {
            return {
                value: opt.optionName,
                label: opt.optionName,
                inputType: opt.optionSelectType,
                isMandatory: opt.isMandatory,
            };
        });
    };

    if (!selectedObservation) {
        return null;
    }

    return (



        <div className="d-flex justify-content-start">
            <div style={{ width: "60%", paddingRight: 15 }}>
                <div className='observation-fields'>     <DynamicField
                    observationType={observationType}
                    name={observationType === ObservationType.Building || observationType === ObservationType.Floor || observationType === ObservationType.Room || observationType === ObservationType.Unit ? "optionValue" : "observationOption"}
                    type={getObservationInputType()}
                    optionValues={getObservationOptionSelectValues()}
                    observationName={selectedObservation.observationName}
                />
                    <InputTextArea label="Note" name="note" />
                </div>
            </div>
            <div className="m-auto" style={{ marginRight: "10px", width: "40%" }}>
                <div className='border  pt-3  mb-1 pe-3 ps-3' style={{ height: "160px", borderRadius: '5px', backgroundColor: '#efefef' }}>
                    <div className='d-flex'>
                        <label className={'mt-1'} htmlFor="isStormRelated">Event Related </label>
                        <div className='mx-2'>
                            <ToggleSwitch
                                name={field.name} />
                        </div>

                    </div>

                    {isEvent && (
                        <div className='checkboxwrapper'>
                            <MultiSelectField
                                align={MultiSelectAlign.Column}
                                isFullRow
                                name='perils'
                                optionValues={result ? result[0].values.map((e: MasterValue): MultiSelctOptionValue => {
                                    return {
                                        label: e.value === WINDOWS_DOOR_OTHER_OPTION ? 'Other' : e.value,
                                        value: e.value,
                                        inputType: e.value === WINDOWS_DOOR_OTHER_OPTION ? InputFieldType.Text : InputFieldType.None

                                    }
                                }) : []}
                            />
                        </div>
                    )}
                </div>

            </div>
        </div>






    );
};
