import { useFormikContext } from 'formik';
import { ReactElement } from 'react';

export const SUBMIT_ACTION_TYPE_KEY_NAME = 'SUBMIT_ACTION_TYPE';

export enum SubmitActionType {
    SAVE_AND_ADDNEW = 'SAVE_AND_ADDNEW',
    SAVE_AND_CLOSE = 'SAVE_AND_CLOSE',
}

interface FormikButtonProps {
    customButtons?: ReactElement<any>;
    onClose?: () => any;
    showAddAnother?: boolean;
}

export const FormikBttonsComponent = ({
    customButtons,
    onClose,
    showAddAnother,
}: FormikButtonProps) => {
    const { submitForm, setFieldValue, resetForm } = useFormikContext();

    const handleSubmit = async (type: SubmitActionType) => {
        setFieldValue(SUBMIT_ACTION_TYPE_KEY_NAME, type, false);
        await submitForm();
        resetForm();
    };

    return (
        <div className="d-flex margin-fix mt-3">
            <button className="btn btn-outline-secondary btn-sm" onClick={onClose}>
                Cancel
            </button>
            <div className="dlg-custom-actn">{customButtons}</div>
            <div className='ms-auto '>
                {showAddAnother && (
                    <button
                        type="button"
                        className=" btn btn-outline-primary me-2 btn-sm"
                        onClick={() => handleSubmit(SubmitActionType.SAVE_AND_ADDNEW)}
                    >
                        Save & Add Another
                    </button>
                )}
                <button
                    type="button"
                    className=" btn btn-primary btn-sm"
                    onClick={() => handleSubmit(SubmitActionType.SAVE_AND_CLOSE)}
                >
                    Save
                </button>
            </div>
        </div>
    );
};
