import { useLoadData } from '../../hooks/use-load-data.hook';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { GridCommandCell } from '../../components/grid-command-cell.component';
import { useEffect, useState } from 'react';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { Observation } from '../../models/masters/observation';
import { EditObservationMaster } from './edit--observation-master.component';
import { useNavigate } from 'react-router';
import { PermissionName } from '../../models/user-security/permission.name';

export const ObservationMasterGridComponent = (props: { observationType: string }) => {
    const history = useNavigate();
    const [editMasterValue, setEditMasterValue] = useState<Observation>();
    const [deleteMasterValue, setDeleteMasterValue] = useState<DeletePostDataInfo>();
    const [gridData, setGridData] = useState<Observation[]>();
    const { isLoading, result, isError } = useLoadData<Observation[]>(
        `observation/list?type=${props.observationType}`,
    );
    useEffect(() => {
        if (result) {
            setGridData(result);
        }
    }, [result]);

    const onSaveComplete = (status: SubmitStatus, data: Observation) => {
        if (status === SubmitStatus.Completed) {
            const newData = addOrUpdateArrayItem(gridData, data, 'observationID');
            setGridData(newData);
        }
        setEditMasterValue(null);
    };

    const onDeleteComplete = (status: DeleteStatus, data: Observation) => {
        if (status === DeleteStatus.Completed) {
            const newData = deleteArrayItem(gridData, data, 'observationID');
            setGridData(newData);
        }
        setDeleteMasterValue(null);
    };

    const onEnterEdit = (data: Observation) => {
        setEditMasterValue({ ...data });
    };

    const onAddNew = () => {
        const masterValue: Observation = {
            observationID: 0,
            observationType: props.observationType,
            observationName: '',
            note: '',
            color: '',
            isMandatory: false,
        };
        setEditMasterValue(masterValue);
    };

    const onDelete = (data: Observation) => {
        const { observationID } = data;
        setDeleteMasterValue({
            data,
            route: `observation/${observationID}`,
        });
    };

    const onView = (data: Observation) => {
        history(`/observation-master-options/${data.observationID}`);
    };

    const CommandCell = (props: GridCellProps) => (
        <GridCommandCell
            {...props}
            view={onView}
            edit={onEnterEdit}
            remove={onDelete}
            add={onAddNew}
            idPropertyName="ID"
            viewPermissionName={PermissionName.ViewMaster}
            editPermissionName={PermissionName.EditMaster}
            deletePermissionName={PermissionName.DeleteMaster}
        />
    );

    if (isError) {
        return <div>Error</div>;
    }
    return isLoading ? (
        <div>Loading</div>
    ) : (
        <>
            <div className="d-flex">
                <button title="Add new" className="btn btn-outline-primary ms-auto mb-3 btn-sm mt-2" onClick={onAddNew}>
                    ADD NEW OBSERVATION TYPE
                </button>
            </div>
            <Grid data={gridData}>
                {/* <GridColumn width="100" field="observationID" title="ID" editable={false} />*/}
                <GridColumn field="observationName" title="Observation Name" />

                {/* <GridColumn field="note" title="Note" />*/}
                <GridColumn field="isMandatory" className="title-case" title="Mandatory" />
                <GridColumn cell={CommandCell} width="220px" />
            </Grid>
            {editMasterValue && (
                <EditObservationMaster data={editMasterValue} onClose={onSaveComplete} />
            )}
            {deleteMasterValue && (
                <DeleteModelDialog
                    title="Delete Observation"
                    deleteData={deleteMasterValue}
                    onClose={onDeleteComplete}
                >
                    <div>
                        Are you sure you want to delete - {deleteMasterValue.data.observationName}?
                    </div>
                </DeleteModelDialog>
            )}
        </>
    );
};
