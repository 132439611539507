import { useFormikContext } from 'formik';
import { InputField } from '../../../components/form/input.feild.component';
import { SelectField } from '../../../components/form/select-field.component';
import { Building } from '../../../models/claim/building';
import NumericField from '../../../components/form/numeric-input-field.component';

interface EditRoomFromProps {
    building: Building;
}

export const EditRoomFrom = ({ building }: EditRoomFromProps) => {
    const { getFieldProps } = useFormikContext();

    const getFloorOptions = () => {
        return building?.floors.map<any>((f) => {
            return { value: f.floorID, label: f.floorName };
        });
    };

    const getUnitOptions = () => {
        const floorId = getFieldProps<number>('floorID');
        const floor = building?.floors.find((f) => f.floorID == floorId.value);
        return floor?.units?.map<any>((u) => {
            return { value: u.unitID, label: u.unitName };
        });
    };

    return (
        <>
            <SelectField
                label="Select Floor"
                name="floorID"
                options={getFloorOptions()}
                unSelectValue={0}
            />
            <SelectField
                label="Select Unit"
                name="unitID"
                options={getUnitOptions()}
                unSelectValue={0}
            />
            <InputField label="Room Name" name="roomName" />
            <NumericField label="Room Number" name="roomNumber" />
        </>
    );
};
