import { ReactNode, useEffect } from 'react';
import { isNil, map, reduce, uniq } from 'lodash';
import { LOGIN_DEMO_KEY, StorageKey } from '../constants';
import { LoginPage } from '../features/login/login.page';
import { useStorage } from '../hooks/use-storage';
import { LoginReponse } from '../models/login-response';
import { ActionType } from './app.context';
import { PermissionName } from '../models/user-security/permission.name';
import { useAppContext } from '../hooks/use-app-context.hook';
import { useLoadData } from '../hooks/use-load-data.hook';
import { LoadingIndicator } from '../components/loading-indicator.component';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import { LoginWithKeyPage } from '../features/login/login-withkey.page';

interface AuthorizeComponentProps {
    children: ReactNode;
}

const queryParams = new URLSearchParams(window.location.search);
const loginKey = queryParams.get(LOGIN_DEMO_KEY);

export const AuthorizeComponent = ({ children }: AuthorizeComponentProps) => {
    const { dispatch } = useAppContext();
    const [authToken, setToken] = useStorage(StorageKey.AuthState, null);
    const { isAuthenticated } = useCurrentUser();
    const {
        isLoading,
        result: userData,
        isError,
    } = useLoadData<LoginReponse>(loginKey ? 'login/key' : 'login/user', isNil(authToken));

    useEffect(() => {
        if (userData) {
            console.log(userData);
            
            initUserContext();
        }
    }, [userData]);

    useEffect(() => {
        if (isError) {
            setToken(null);
        }
    }, [isError]);

    const initUserContext = async () => {
        const { user } = userData;
        const currentPermissions = reduce(
            user.userRoles,
            (prevValue: PermissionName[], currentValue) => {
                return [...prevValue, ...map(currentValue.permissions, (p) => p.permissionName)];
            },
            [],
        );
        dispatch({
            type: ActionType.UpdateUser,
            data: { ...user, currentPermissions: uniq(currentPermissions), isAuthenticated: true },
        });
    };

    if (isNil(authToken)) {
        return loginKey ? <LoginWithKeyPage loginKey={loginKey} /> : <LoginPage />;
    }
    return (
        <>
            <LoadingIndicator isLoading={isLoading} />
            {isError && (
                <div>Error loading user. Please refresh the page or contact your administrator</div>
            )}
            {isAuthenticated && !isLoading && children}
        </>
    );
};
