import { Spinner } from 'react-bootstrap';

interface LoadingIndicatorProps {
    isLoading: boolean;
}

export const LoadingIndicator = ({ isLoading }: LoadingIndicatorProps) => {
    if (!isLoading) {
        return null;
    }
    return (
        <div
            style={{
                position: 'relative',
                zIndex: 1330,
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
                top: 0,
                left: 0,
                textAlign: 'center',
            }}
        >
            <Spinner animation="border" role="form">
                {/* <span className="visually-hidden">Loading...</span> */}
            </Spinner>
            <div className='row' style={{ marginTop: '10px', color: 'lightgray' }}>
                <span><h5>Loading...</h5></span>
            </div>
        </div >
    );
};
