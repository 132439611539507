import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './core/app.context';
import { FooterComponent } from './core/footer.component';
import { HeaderComponent } from './core/header.component';
import { RouterView } from './core/router.view.component';
import { AuthorizeComponent } from './core/authorize.component';
import '@progress/kendo-theme-default/dist/all.css';
import './App.scss';
import { Provider } from 'react-redux';
import appstore from './store';

const App = () => {
    return (
        <AppContextProvider>
            <AuthorizeComponent>
                <Provider store={appstore}>
                    <Router>
                        <div className="wrapper">
                            <HeaderComponent />
                            <RouterView />
                        </div>
                        <FooterComponent />
                    </Router>
                </Provider>
            </AuthorizeComponent>
        </AppContextProvider>
    );
};

export default App;
