import { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { GuardedComponent } from '../../components/guarded.componemt';
import { Claim } from '../../models/claim/claim';
import { PermissionName } from '../../models/user-security/permission.name';

interface ClaimInfoPopoverProps {
    claim: Claim;
    onEditClaimClick: any;
}

export const ClaimInfoPopover = ({ claim, onEditClaimClick }: ClaimInfoPopoverProps) => {
    const [showPopover, setShowPopover] = useState(false);

    const placement = 'bottom';

    const onToggle = (show: boolean) => {
        setShowPopover(show);
    };

    if (!claim) {
        return null;
    }

    return (
        <OverlayTrigger
            trigger="click"
            key={placement}
            placement={placement}
            onToggle={onToggle}
            overlay={
                <Popover className="claim-info-pop" id={`popover-positioned-${placement}`}>
                    <Popover.Body>
                        <div>
                            <label className="label">Project Name</label>
                            <h6>{claim.buildingComplexName}</h6>
                        </div>
                        <div>
                            <label className="label">Insured</label>
                            <h6>{claim.client}</h6>
                        </div>
                        <div>
                            <label className="label">Claim#</label>
                            <h6>{claim.claimNumber}</h6>
                        </div>
                        <div>
                            <label className="label">Keystone#</label>
                            <h6>{claim.keystoneNumber}</h6>
                        </div>
                        <div>
                            <label className="label">Address</label>
                            <h6>{claim.buildingComplexAddress}</h6>
                        </div>
                        <GuardedComponent permission={PermissionName.EditClaim}>
                            <div className="d-flex">
                                <button
                                    onClick={onEditClaimClick}
                                    className="btn btn-primary btn-sm ms-auto mt-2"
                                >
                                    Edit
                                </button>
                            </div>
                        </GuardedComponent>
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                className={
                    showPopover ? 'claiminfo-toggle claiminfo-toggle-up' : 'claiminfo-toggle'
                }
            >
                Claim Info
            </Button>
        </OverlayTrigger>
    );
};
