import { filter, find, forEach, isEmpty, map, remove, some, uniq } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLoadDataMany } from '../../hooks/use-load-data-many.hook';
import { usePermission } from '../../hooks/use-permission.hook';
import { PermissionName } from '../../models/user-security/permission.name';
import { EntityImage } from '../../models/building-image/entity-image';
import { EntityImageData } from '../../models/building-image/entity-image-data';
import { Shape } from '../../models/building-image/image-draw.types';
import { Building } from '../../models/claim/building';
import { ClaimObservation } from '../../models/claim/claim-observation';
import { EntityType } from '../../models/claim/entity-type';
import { Observation } from '../../models/masters/observation';
import { ObservationType } from '../../models/masters/observation-type';
import { CreateShapeDetailsModelDialog } from './create-shape-details-dialog.component';
import { ImageDrawingComponent } from './image-drawing/image-drawing.component';
import { ViewEntityDetailsModelDialog } from './view-entity-details-dialog.component';

interface BuildingImageViewProps {
    buildingData: Building;
    selectedImage: EntityImage;
    customButtons?: ReactNode;
    selectedObservations: Observation[];
    onObservationDetailsChanged: any;
}

class ImageObservation {
    observationID: number;
    observation: string;
    note: string;
    entityType: EntityType;
    entityID: number;
    entityName: string;
    cordinateID: number;
    shapeJsonData: string;
}

export const BuildingImageView = ({
    buildingData,
    selectedImage,
    customButtons,
    selectedObservations,
    onObservationDetailsChanged,
}: BuildingImageViewProps) => {
    const { hasPermission: hasEditBuildingPermission } = usePermission(PermissionName.EditBuilding);
    const [entityImages, setEntityImages] = useState<EntityImageData[]>([]);
    const [imageShapes, setImageShapes] = useState<Shape[]>([]);
    const [editShapeData, setEditShapeData] = useState<Shape>();
    const [deleteShapeData, setDeleteShapeData] = useState<DeletePostDataInfo>();
    const [viewEntityDetailsData, setViewEntityDetails] = useState<EntityImageData>();

    const { isLoading, result } = useLoadDataMany(
        [
            { name: 'PointsData', route: `image/observations/${selectedImage.imageID}` },
            {
                name: 'WindowObservationMasterData',
                route: `observation/list?type=${ObservationType.Window}`,
            },
            {
                name: 'DoorObservationMasterData',
                route: `observation/list?type=${ObservationType.Door}`,
            },
        ],
        selectedImage.imageID.toString(),
    );

    useEffect(() => {
        setEntityImages([]);
    }, [selectedImage]);

    useEffect(() => {
        if (result) {
            const pointsData = find(result, (r) => r.name === 'PointsData').data;
            if (pointsData && !isEmpty(pointsData)) {
                const entityImages = convertToEntityImageData(pointsData);
                setEntityImages(entityImages);
            }
        }
    }, [result]);

    useEffect(() => {
        if (entityImages && result) {
            const shapes = convertToShapeObject(entityImages);
            updateShapeVisibility(selectedObservations, shapes);

            const allObservationMasterData = getObservationMasterData();
            const observationMasterData = filter(allObservationMasterData, (obs) => {
                return some(entityImages, (e) =>
                    some(
                        e.observations,
                        (o) =>
                            o.observationName === obs.observationName &&
                            o.observationType.toLowerCase() === obs.observationType.toLowerCase(),
                    ),
                );
            });
            onObservationDetailsChanged(observationMasterData);
            setImageShapes(shapes);
        }
    }, [entityImages]);

    useEffect(() => {
        if (selectedObservations) {
            highlightShapes(selectedObservations);
        }
    }, [selectedObservations]);

    const getObservationMasterData = (): Observation[] => {
        const windowObservationMaster: Observation[] = find(
            result,
            (r) => r.name === 'WindowObservationMasterData',
        ).data;
        const doorObservationMaster: Observation[] = find(
            result,
            (r) => r.name === 'DoorObservationMasterData',
        ).data;
        return [...windowObservationMaster, ...doorObservationMaster];
    };

    const highlightShapes = (observations: Observation[]) => {
        const clonedSelectedImageShapes = [...imageShapes];
        updateShapeVisibility(observations, clonedSelectedImageShapes);
        setImageShapes(clonedSelectedImageShapes);
    };

    const updateShapeVisibility = (observations: Observation[], shapes: Shape[]) => {
        const highlighOthers = some(observations, (n) => n.observationName === 'OTHERS');
        forEach(shapes, (shape) => {
            const { tagData } = shape;
            shape.isHidden = !some(tagData.observations, (obs) =>
                some(
                    observations,
                    (o) =>
                        obs.observationName === o.observationName &&
                        o.observationType.toLowerCase() === obs.observationType.toLowerCase(),
                ),
            );
            if (highlighOthers && shape.isHidden) {
                shape.isHidden = !isEmpty(shape.colorTags);
            }
        });
    };

    const convertToShapeObject = (entityImages: EntityImageData[]): Shape[] => {
        return map(entityImages, (ei) => {
            return {
                point1: ei.point1,
                point2: ei.point2,
                isEditable: true,
                isHighlighted: false,
                tagData: ei,
                colorTags: uniq(map(ei.observations, (o) => o.color)).filter(Boolean),
            };
        });
    };

    const convertToEntityImageData = (imageObservations: ImageObservation[]): EntityImageData[] => {
        const entityImages: EntityImageData[] = [];
        const observationMasterData = getObservationMasterData();
        forEach(imageObservations, (io) => {
            let entityImage = find(
                entityImages,
                (i) => i.entityId === io.entityID && i.entityType === i.entityType,
            );
            if (!entityImage) {
                const points = JSON.parse(io.shapeJsonData);
                entityImage = {
                    iD: io.cordinateID,
                    entityId: io.entityID,
                    entityType: io.entityType,
                    entityName: io.entityName,
                    point1: points.point1,
                    point2: points.point2,
                    observations: [],
                };
                entityImages.push(entityImage);
            }

            const obs = find(entityImage.observations, (o) => o.observationID === io.observationID);
            if (!obs) {
                entityImage.observations.push({
                    observationName: io.observation,
                    observationID: io.observationID,
                    color: getObservationColor(
                        observationMasterData,
                        io.observation,
                        io.entityType,
                    ),
                    note: '',
                    isMandatory: false,
                    observationType: io.entityType,
                });
            }
        });

        return entityImages;
    };

    const getObservationColor = (
        observationMasterData: Observation[],
        observation: string,
        observationType: string,
    ): string => {
        return find(
            observationMasterData,
            (m) =>
                m.observationName === observation &&
                m.observationType.toLowerCase() === observationType.toLowerCase(),
        )?.color;
    };

    const onEntityUpdate = (isDirty: boolean, entity: any, observations: ClaimObservation[]) => {
        if (isDirty && entity) {
            const observationMasterData = getObservationMasterData();
            const { entityId, entityType } = viewEntityDetailsData;
            const clonedEntityImages = [...entityImages];
            let entityImage = find(
                clonedEntityImages,
                (s) => s.entityId === entityId && s.entityType === entityType,
            );

            if (!entityImage) {
                entityImage = { ...viewEntityDetailsData };
                clonedEntityImages.push(entityImage);
            }
            entityImage.entityName =
                entityType === EntityType.Window ? entity.windowName : entity.doorName;
            entityImage.observations = [];

            forEach(observations, (obs) => {
                entityImage.observations.push({
                    observationName: obs.observation,
                    observationID: obs.observationID,
                    color: find(
                        observationMasterData,
                        (m) =>
                            m.observationName === obs.observation &&
                            m.observationType.toLowerCase() === obs.entityType.toLowerCase(),
                    )?.color,
                    note: '',
                    isMandatory: false,
                    observationType: obs.entityType,
                });
            });
            setEntityImages(clonedEntityImages);
        }
        setViewEntityDetails(null);
    };
    const onEntityDelete = (entity: any) => {
        deleteEntity(viewEntityDetailsData);
    };

    const deleteEntity = (entity: EntityImageData) => {
        const clonedEntityImages = [...entityImages];
        remove(
            clonedEntityImages,
            (s) => s.entityId === entity.entityId && s.entityType === entity.entityType,
        );
        setEntityImages(clonedEntityImages);
        setViewEntityDetails(null);
    };

    const handleSaveShapeComplete = (shape: Shape) => {
        if (shape) {
            const clonedEntityImages = [...entityImages];
            const entityImage = shape.tagData;
            remove(
                clonedEntityImages,
                (s) =>
                    s.entityId === entityImage.entityId &&
                    s.entityType.toLocaleLowerCase() === entityImage.entityType.toLocaleLowerCase(),
            );
            entityImage.point1 = shape.point1;
            entityImage.point2 = shape.point2;
            clonedEntityImages.push(entityImage);
            setEntityImages(clonedEntityImages);
        }
        setEditShapeData(null);
    };

    const onShapeSave = async (shape: Shape) => {
        setEditShapeData(shape);
        return { success: true };
    };

    const onShapeDeleteClick = async (shape: Shape) => {
        setDeleteShapeData({
            data: shape.tagData,
            route: `image/cordinates/${shape.tagData.iD}`,
        });
        return false;
    };

    const handleDeletePointsComplete = (status: DeleteStatus, data: EntityImageData) => {
        if (status == DeleteStatus.Completed) {
            deleteEntity(data);
        }
        setDeleteShapeData(null);
    };

    if (isLoading) {
        return <LoadingIndicator isLoading={isLoading} />;
    }

    return (
        <>
            <ImageDrawingComponent
                imageUrl={selectedImage?.imagePath}
                initialShapes={imageShapes}
                onShapeSave={onShapeSave}
                onViewDetails={setViewEntityDetails}
                onShapeDelete={onShapeDeleteClick}
                disableDraw={!hasEditBuildingPermission}
                customButtons={customButtons}
            />

            {viewEntityDetailsData && (
                <ViewEntityDetailsModelDialog
                    buildingData={buildingData}
                    entityData={viewEntityDetailsData}
                    onUpdate={onEntityUpdate}
                    onEntityDelete={onEntityDelete}
                />
            )}

            {editShapeData && (
                <CreateShapeDetailsModelDialog
                    currentImage={selectedImage}
                    building={buildingData}
                    shapeData={editShapeData}
                    onClose={handleSaveShapeComplete}
                />
            )}
            {deleteShapeData && (
                <DeleteModelDialog
                    title={`Delete Points`}
                    deleteData={deleteShapeData}
                    onClose={handleDeletePointsComplete}
                >
                    <div>Are you sure you want to delete {deleteShapeData.data.entityName}?</div>
                </DeleteModelDialog>
            )}
        </>
    );
};
