import { useEffect, useState } from 'react';
import { getData } from '../utils/network.client';


export const useLoadData = <T>(route: string, disable = false) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [result, setResult] = useState<T>();

    useEffect(() => {
        if (!disable) {
            loadData();
        }
    }, [route, disable]);
    ``
    const loadData = async () => {
        try {
            setIsError(false);
            setIsLoading(true);
            console.log(route);
            const result = await getData<T>(route);
            if (result.status === 200) {

                setResult(result.data);
            } else {
                setIsError(true);
            }
        } catch (ex) {
            console.log(ex);

            setIsError(true);
        }
        setIsLoading(false);
    };

    return { isLoading, result, isError, reload: loadData };
};
