import { useEffect, useState } from 'react';
import axios from 'axios';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Building } from '../../../models/claim/building';
import { MasterType } from '../../../models/masters/master-type';
import { UnitWindow } from '../../../models/claim/unit-window';
import { EditWindowForm } from './edit-window.form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { EntityType } from '../../../models/claim/entity-type';
import { WINDOWS_DOORS_API_URL, WINDOWS_DOOR_OTHER_OPTION, WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX } from '../../../constants';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { createAttributeObject } from '../../../utils/building.utils';
import { CopyEntityComponent } from '../room/copy-entity.component';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { TemplateModel } from '../../../models/template-model';
import * as Yup from "yup";
import { isEmpty, join, map } from 'lodash';
import { MasterValue } from '../../../models/masters/master-value';

interface EditWindowProps {
    data?: { building: Building; window: UnitWindow; copyFrom?: boolean };
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditWindow = ({ data: { building, window, copyFrom }, onClose }: EditWindowProps) => {
    const { isError, isLoading, result, reload } = useLoadData<MasterType[]>(`masters?type=window`);
    const [showCopyWindow, setShowCopyWindow] = useState<boolean>(!!copyFrom);
    const [templates, setTemplates] = useState<TemplateModel[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateModel | null>(null);
    const [initialData, setInitialData] = useState<any>();

    const title = window.windowID ? 'Edit Window' : 'ADD NEW WINDOW';

    useEffect(() => {
        if (result) {

            setInitialData({
                windowName: window.windowName,
                direction: window.direction,
                windowNotes: window.notes,
                hasDamage: window.hasDamage,
                ...(result && { ...createAttributeObject(result, window.attributes) }),
            });
            console.log(initialData);

        }


    }, [window, result])

    useEffect(() => {
        if (building !== null && building) {
            axios.get(`${WINDOWS_DOORS_API_URL}/api/TemplateAsset/GetGetTemplateAssetsByEntityType/${building.buildingID}/window`)
                .then(response => {
                    setTemplates(response.data);

                    if (response.data) {
                        let templates: TemplateModel[] = response.data as TemplateModel[];
                        if (templates.length > 0) {
                            let defaultTemplate = templates.filter(e => e.templateDefaultID > 0);
                            if (!isEmpty(defaultTemplate)) {
                                setSelectedTemplate(defaultTemplate[0]);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error("Error loading templates:", error);
                });
        }
    }, [building?.buildingID]);

    useEffect(() => {
        // Update initialData based on selectedTemplate and result
        if (selectedTemplate && result && !window.windowID) {
            const newInitialData = {
                observations: window.observations,
                images: window.images,
                //notes: selectedTemplate.notes,
                windowID: window.windowID,
                roomID: window.roomID,
                imageCordinateID: window.imageCordinateID,
                windowName: window.windowName,
                direction: selectedTemplate.direction ?? '',
                // hasDamage: selectedTemplate.hasDamaged,
                ...createAttributeObject(result, selectedTemplate.attributes.map(e => { return { attributeName: e.attributeName, attributeValue: e.attributeValue, attributeid: e.attributeId } })),
            };
            setInitialData(newInitialData);
        }
    }, [selectedTemplate, result]);

    const parseWindowData = (data: any) => {
        window.windowName = data.windowName;
        window.direction = data.direction;
        window.notes = data.windowNotes;
        window.hasDamage = data.hasDamage;
        if (window.roomID === 0) {
            window.roomID = Number(data.roomID);
        }
        window.attributes = result.map(item => ({
            attributeName: item.name,
            attributeValue: item.inputType !== InputFieldType.MultiSelect ? getAttributeValue(item, data) : getMultiValue(item, data),
        })).filter(a => !!a.attributeValue);
        return window;
    };

    const getMultiValue = (item: MasterType, data: any) => {
        let customOption = '';
        if (item.inputType === InputFieldType.MultiSelect) {
            const selectedItems = item.values.filter((v: MasterValue) => {
                if (v.value.toLowerCase() !== WINDOWS_DOOR_OTHER_OPTION.toLowerCase()) {
                    return data[v.value];
                }
                else {
                    if (data[`${v.value}-${v.id}`] === true && data[`${v.value}-${v.id}`] !== "" && data[`${v.value}value-${v.id}`] !== undefined && data[`${v.value}value-${v.id}`] !== '') {
                        customOption = data[`${v.value}value-${v.id}`];

                        return true;
                    }
                }
            }
            );
            console.log(selectedItems);
            let option = join(map(selectedItems, (s) => s.value), ',');
            if (customOption !== '') {
                option = option + ":" + customOption;
            }
            console.log(option);

            return option;
        }

        else if (item.inputType === InputFieldType.Text || item.inputType === InputFieldType.Date) {
            return data[item.name];
        }
        return null;
    };

    const getAttributeValue = (item: MasterType, data: any) => {
        const value: string = data[item.name];
        return value &&
            item.inputType === InputFieldType.SingleSelect &&
            value.toLowerCase() === WINDOWS_DOOR_OTHER_OPTION.toLowerCase()
            ? data[item.name + WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX]
            : value;
    };

    const validationSchema = Yup.object().shape({
        windowName: Yup.string().required('Required'),
    });

    const handleCopyWindow = (sourceWindow: UnitWindow) => {
        setShowCopyWindow(false);
        if (sourceWindow) {
            window.windowName = sourceWindow.windowName;
            window.direction = sourceWindow.direction;
            window.notes = sourceWindow.notes;
            window.attributes = sourceWindow.attributes;
            window.hasDamage = sourceWindow.hasDamage;
        }
    };

    const handleCopyDialogClose = () => {
        onClose?.(SubmitStatus.Cancel, null);
    };

    const handleTemplateChange = (e: DropDownListChangeEvent) => {

        setSelectedTemplate(e.target.value as TemplateModel || null);
    };

    if (showCopyWindow) {
        return (
            <Dialog title={'Copy Window'} onClose={handleCopyDialogClose}>
                <CopyEntityComponent
                    building={building}
                    entityType={EntityType.Window}
                    onCopyEntity={handleCopyWindow}
                />
            </Dialog>
        );
    }

    return (
        <FormikModelDialog
            width='60%'
            title={title}
            show={!!window}
            initialData={initialData} // Pass initialData as stateful variable
            validationSchema={validationSchema}
            postRoute="windows/window"
            postDataFormatter={parseWindowData}
            onClose={onClose}
        >
            <>

                <LoadingIndicator isLoading={isLoading} />
                {result && (<>

                    <EditWindowForm
                        onTemplateChange={handleTemplateChange}
                        selectedTemplate={selectedTemplate}
                        templates={templates}
                        attributes={result}
                        building={building}
                        window={window}
                    /></>
                )}
            </>
        </FormikModelDialog>
    );
};
