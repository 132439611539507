import { ErrorMessage, useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isEmpty, isNil } from 'lodash';

interface DatePickerFieldProps {
    name: string;
    label?: string;
    startDate?: Date;
}

export const DatePickerField = ({
    name,
    label,
    startDate = new Date(1900, 1, 1),
}: DatePickerFieldProps): JSX.Element => {
    const [{ value }, meta, { setValue }] = useField<Date>(name);
    const { setFieldValue } = useFormikContext();

    return (
        <div>
            {label && <label className="label">{label}</label>}
            <DatePicker
                selected={value?value===new Date()?null:value:null}
                onChange={(date: Date) => {
                    setValue(date);
                    setFieldValue(name, date);
                }}
            />
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
