import act, { useState, useEffect } from 'react';
import { ExpansionPanel, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ObservationComponent } from './observation.component';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import ImageList from '../../../components/form/image-list.component';
import { ImgFile, fetchFile } from '../../../utils/uploadfile.util';
import { isEmpty } from 'lodash';
import { Gallery, Image } from 'react-grid-gallery';
import src from 'react-select/dist/declarations/src';
import { number, string } from 'yup';
import { DisplayImages } from '../../../components/display-images';
import FileDialogButton from '../../../components/add-image.button.component';
import { PermissionName } from '../../../models/user-security/permission.name';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { LoadingIndicator } from '../../../components/loading-indicator.component';

interface ObservationListComponentProps {
    observations: ClaimObservation[];
    imgList?: ImgFile[];
    onEditObservation?: any;
    onDeleteObservation?: any;
    onOpenGallery?: any;
    onCopyObservation?: (observation: ClaimObservation) => any;
    onShowObservationPhotos?: any;
    photos?: Image[];
    onAddNewObservationClick?: Function;
    oncopyobservation?: Function;
    ispopup?: boolean;
    addphoto?: Function;
    handleDelete?: Function;
    handleSave?: Function;
    handleCancel?: Function;
}

export const ObservationListComponent: React.FC<ObservationListComponentProps> = ({
    observations,
    handleCancel,
    handleDelete,
    handleSave,
    onEditObservation,
    addphoto,
    onDeleteObservation,
    onOpenGallery,
    onAddNewObservationClick,
    oncopyobservation,
    ispopup = false,
    imgList,
    onCopyObservation,

    onShowObservationPhotos,
    photos,
}: ObservationListComponentProps) => {
    const [selectedState, changeSelectedState] = useState({
        activeObservation: null,
        observations: [],
    });


    useEffect(() => {
        changeSelectedState({ activeObservation: null, observations: observations });
    }, [observations]);

    const toggleActive = (index: number) => {
        changeSelectedState({
            ...selectedState,
            activeObservation: selectedState.observations[index],
        });
    };
    const toggleactivestyle = (index: number) => {
        //alert('DF');
        if (selectedState.observations[index] === selectedState.activeObservation) {
            return 'boservation-grid active';
        } else {
            return 'boservation-grid';
        }
    };

    return (
        <div>
            <div className="bg_white_stack">
                <div className="stack_title">
                    <h4>Observations</h4>{' '}
                    {!ispopup && (
                        <div>
                            <GuardedComponent permission={PermissionName.AddObservation}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                        onAddNewObservationClick();
                                    }}
                                >
                                    Add Observation
                                </button>
                            </GuardedComponent>

                            <GuardedComponent permission={PermissionName.AddObservation}>
                                <button
                                    className="btn btn-outline-primary btn-sm ms-2"
                                    onClick={() => {
                                        oncopyobservation();
                                    }}
                                >
                                    Copy From Other Observation
                                </button>
                            </GuardedComponent>
                        </div>
                    )}
                </div>
                <div className="observation_2col">
                    <div>
                        {(observations &&
                            observations.length > 0 &&
                            selectedState.observations?.map((item, index) => (
                                <ObservationComponent
                                    className={toggleactivestyle(index)}
                                    onselect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                        toggleActive(index);
                                    }}
                                    key={`Observation_${item.observationID}`}
                                    observation={item}
                                    onEditObservation={onEditObservation}
                                    onDeleteObservation={onDeleteObservation}
                                    onOpenGallery={onOpenGallery}
                                    onCopyObservation={onCopyObservation}
                                    onShowObservationPhotos={onShowObservationPhotos}
                                />
                            )))}
                    </div>

                    <div>
                        {photos && photos.length > 1 ? (
                            <div>

                                <Gallery
                                    thumbnailStyle={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    images={photos.filter((e) => e.src !== '')}
                                    enableImageSelection={false}
                                />
                            </div>
                        ) : (
                            <div className='alertDesign'>
                                <div>
                                    <div className='images'></div>
                                    {photos && photos.length == 1 ? (<div style={{ textAlign: 'center' }}><b>There are no photos associated with this observation. Click Edit to add photos</b></div>) : (
                                        <div><b>Select an observation from the left section to view associated photos</b></div>)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="bg_white_stack">
                <div className="stack_title">
                    <h4>Photos</h4>{' '}
                    {!ispopup && (<div>
                        <GuardedComponent permission={PermissionName.AddObservation}>
                            <FileDialogButton
                                handleImgSelect={(e) => {
                                    addphoto(e);

                                }}
                            ></FileDialogButton>{' '}
                        </GuardedComponent>
                    </div>)}
                </div>
                <div className="photoGal-wrap">

                    <DisplayImages

                        handleCancel={() => handleCancel()}
                        handleDelete={(img: ImgFile) => {
                            handleDelete(img);
                        }}
                        handlesave={(img: ImgFile) => {
                            handleSave(img);
                        }}
                        imgList={imgList ? imgList : []}
                    />


                </div>
            </div>
        </div >
    );
};
