import { PanelBar, PanelBarItem, PanelBarItemProps } from '@progress/kendo-react-layout';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Room } from '../../../models/claim/room';
import { UnitDoor } from '../../../models/claim/unit-door';
import videoimg from '../../../images/file-video';
import ImageLink from '../../../images/image-link';
import imageimg from '../../../images/file-image';
import EyeIcon from '../../../images/eye';
import { useEffect, useRef, useState } from 'react';
import { Building } from '../../../models/claim/building';
import { EditDoor } from './edit-door.component';
import { EntityType } from '../../../models/claim/entity-type';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../../utils/array.util';
import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { EditObservation } from '../observation/edit-observation.component';
import { ObservationType } from '../../../models/masters/observation-type';
import { ObservationListComponent } from '../observation/observation-list.component';
import { ImageListModelDialog } from '../../image/image-list-model-dialog.component';
import editImg from '../../../images/edit-gray';
import DeleteGreyIcon from '../../../images/delete-gray';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { PermissionName } from '../../../models/user-security/permission.name';
import { LinkImageModelDialog } from '../../building-image/link-image-model-dialog.component';
import FileImage from '../../../images/file-image';
import FileVideoIcon from '../../../images/file-video';
import EditGrayIcon from '../../../images/edit-gray';
import FileDialogButton from '../../../components/add-image.button.component';
import {
    ImgFile,
    deleteFile,
    editComments,
    fetchFile,
    uploadImages,
} from '../../../utils/uploadfile.util';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { fetchFiles } from '../../../api-client.ts/fileapi';
import { UploadedFile } from '../../../redux/reducers/filereducer';
import ImageUploader from '../../../components/add-images.component';
import { Image } from 'react-grid-gallery';
import DeleteIcon from '../../../images/delete';
import EditIcon from '../../../images/edit';

interface DoorsComponentProps {
    building: Building;
    room: Room;
}

export const DoorsComponent = ({ building, room }: DoorsComponentProps) => {
    const { isLoading, result, isError } = useLoadData<Room>(`rooms?roomid=${room?.roomID}`);
    const [roomData, setRoomData] = useState<Room>();
    const [imgpreview, showImgPreview] = useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });
    const [imageListData, setImageListData] = useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    const [data, setEditDoor] = useState<{ building: Building; editDoor: UnitDoor }>();
    const [deleteDoorData, setDeleteDoorData] = useState<DeletePostDataInfo>();
    const [newObservation, setNewObservation] = useState<{
        newObservation: ClaimObservation;
        copyFrom?: boolean;
        updateOption: ClaimObservationOption;
    }>();
    const [deleteObservation, setDeleteObservation] = useState<DeletePostDataInfo>();
    const [linkImageData, setLinkImageData] = useState<{
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }>();
    const [photos, setPhoto] = useState<Image[]>();
    const [imgList, setImgList] = useState<ImgFile[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const uploadedfileList = useRef<UploadedFile[]>([]);
    const observationtype = useRef<{ entityType: ObservationType; entityID: number }>({
        entityType: ObservationType.Door,
        entityID: 0,
    });
    const handleAddFileToImgList = async (file: File) => {
        await uploadImages([file], {
            EntityID: observationtype.current.entityID.toString(),
            EntityType: observationtype.current.entityType,
            IsInterior: false,
            Comments: imgpreview.comment.toString(),
        }).then((value: UploadedFile) => {
            imgList.push({
                comment: imgpreview.comment!,
                file: file,
                isbaseImg: value.isBase,
                baseID: 0,
                entityID: value.entityID,
                entityType: value.entityType,
                fileID: value.fileID.toString(),
                userGuid: '',
            });
            loadImages();
            showImgPreview({
                imgpreview: false,
                src: null,
                comment: '',
            });
        });
    };

    useEffect(() => {
        loadImages();
    }, []);
    /* function to show picture of selected observation*/
    const onShowObservationPhotos = (observation: ClaimObservation) => {
        setPhoto([
            ...observation.photos.map(
                (image, index): Image => ({
                    src: image.fileCDN,
                    height: 200,
                    width: 200,
                    key: image.observationID,
                    orientation: 1,
                    caption: image.comments,
                }),
            ),
        ]);
    };

    const loadImages = async () => {
        try {
            setPhoto([]);
            const action = await dispatch(
                fetchFiles({
                    FileType: 'image',
                    UserID: 0,
                    EntityType: observationtype.current.entityType,
                    EntityID: observationtype.current.entityID,


                }),
            );

            if (action.payload) {
                let uploadedFileArray = action.payload as UploadedFile[];

                uploadedfileList.current = uploadedFileArray;

                let files = await Promise.all(
                    uploadedFileArray.map(async (e) => {
                        return fetchFile(e.fileCDN,
                            e.comments,
                            e.entityID.toString(),
                            observationtype.current.entityType,
                            e.isBasePhoto,
                            e.fileID.toString(),
                            e.basePhotoID
                        );
                    }),
                );

                setImgList(files);
            } else {
                setImgList([]);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setImgList([]);
        }
    };

    useEffect(() => {
        if (result) {
            const roomData = { ...result };
            setRoomData(roomData);
        }
    }, [result]);

    const openGallery = (data: { entityType: EntityType; entityId: number }) => {
        setImageListData(data);
    };

    const closGallery = () => {
        setImageListData(null);
    };

    const onAddDoor = () => {
        const selectedDoor: UnitDoor = {
            roomID: room.roomID,
            doorID: 0,
            doorName: '',
            direction: '',
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditDoor({ building: null, editDoor: selectedDoor });
    };

    const onEditDoor = (selectedDoor: UnitDoor) => {
        setEditDoor({ building: null, editDoor: selectedDoor });
    };
    const onDeleteDoor = (selectedDoor: UnitDoor) => {
        setDeleteDoorData({
            data: selectedDoor,
            route: `doors/delete/${selectedDoor.doorID}`,
        });
    };

    const onAddObservationClick = (door: UnitDoor, copyFrom: boolean) => {
        const observation = new ClaimObservation();
        observation.observationID = 0;
        observation.entityType = ObservationType.Door;
        observation.entityID = door.doorID;
        setNewObservation({
            newObservation: observation,
            copyFrom: copyFrom,
            updateOption: null,
        });
    };
    const onEditObservationClick = (
        observation: ClaimObservation,
        updateOption: ClaimObservationOption,
    ) => {
        setNewObservation({
            newObservation: observation,
            updateOption: updateOption,
        });
    };
    const onDeleteObservationClick = (claimObservation: ClaimObservation) => {
        setDeleteObservation({
            data: claimObservation,
            route: `ClaimObservation/${claimObservation.observationID}`,
        });
    };
    const handleDeleteDoorComplete = (status: DeleteStatus, data: UnitDoor) => {
        if (status === DeleteStatus.Completed) {
            roomData.doors = deleteArrayItem(roomData.doors, data, 'doorID');
            setRoomData({ ...roomData });
        }
        setDeleteDoorData(null);
        setPhoto([]);
        setImgList([]);
    };

    const handleSaveDoorComplete = (status: SubmitStatus, data: UnitDoor) => {
        if (status === SubmitStatus.Completed) {
            roomData.doors = addOrUpdateArrayItem(roomData.doors, data, 'doorID');
            setRoomData({ ...roomData });
            setEditDoor(null);
        } else {
            setEditDoor(null);
        }
        setPhoto([]);
        setImgList([]);
        loadImages();
    };

    const handleSaveObservationComplete = (status: SubmitStatus, data: ClaimObservation) => {
        if (status === SubmitStatus.Completed) {
            const currentDoor = roomData.doors.find((p) => {
                return p.doorID === data.entityID;
            });
            currentDoor.observations = addOrUpdateArrayItem(
                currentDoor.observations,
                data,
                'observationID',
            );
            setRoomData({ ...roomData });
            setNewObservation(null);
        }
        setNewObservation(null);
        setPhoto([]);
    };

    const handleDeleteObservationComplete = (status: DeleteStatus, data: ClaimObservation) => {
        if (status === DeleteStatus.Completed) {
            const door = roomData.doors.find((d) => d.doorID === data.entityID);
            door.observations = deleteArrayItem(door.observations, data, 'observationID');
            setRoomData({ ...roomData });
        }
        setDeleteObservation(null);
        setPhoto([]);
    };

    const handleLinkWinodwComplete = () => {
        setLinkImageData(null);
    };

    const linkWindowImage = (data: {
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }) => {
        setLinkImageData(data);
    };

    const doorData = () => {
        if (roomData) {
            const panelItems: PanelBarItemProps[] = roomData.doors.map<PanelBarItemProps>(
                (item): PanelBarItemProps => {
                    return {
                        id: item.doorID.toString(),
                        title: (
                            <div className="structureList-grid">
                                <div>
                                    <h5 className="text-normal">{item.doorName}</h5>
                                </div>
                                <div className="">
                                    <h6 className="text-normal">{item.direction}</h6>
                                </div>
                                <div className="options-list">
                                    {item.attributes.map<any>((attribute) => (
                                        <div>{attribute.attributeValue}</div>
                                    ))}
                                </div>
                                <div>
                                    <button
                                        className="tag-buttons"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            linkWindowImage({
                                                entityType: EntityType.Door,
                                                entityId: item.doorID,
                                                entityName: item.doorName,
                                                direction: item.direction,
                                            });
                                        }}
                                    >
                                        {item.imageCordinateID > 0 ? <EyeIcon /> : <ImageLink />}
                                        {/*   <img
                                            alt="eyeImage"
                                            src={item.imageCordinateID > 0 ? eyeImg : photolink}
                                        /> */}
                                    </button>
                                </div>
                                <div className="d-none">
                                    <FileVideoIcon></FileVideoIcon>{' '}
                                    {/* <img alt="VideoImg" src={videoimg} /> */}
                                    <span className="tagtop">0</span>
                                </div>
                                <div>
                                    <button
                                        className="tag-buttons"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openGallery({
                                                entityType: EntityType.Door,
                                                entityId: item.doorID,
                                            });
                                        }}
                                    >
                                        <FileImage></FileImage>{' '}
                                        {/* <img alt="tagtop" src={imageimg} /> */}
                                        <span className="tagtop">{item.images}</span>
                                    </button>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.EditDoor}>
                                        <button
                                            className="tag-buttons"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onEditDoor(item);
                                            }}
                                        >
                                            <EditIcon></EditIcon>{' '}
                                            {/* <img alt="editImg" src={editImg} /> */}
                                        </button>
                                    </GuardedComponent>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.DeleteDoor}>
                                        <button
                                            className="tag-buttons"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteDoor(item);
                                            }}
                                        >
                                            <DeleteIcon></DeleteIcon>{' '}
                                            {/* <img alt="deleteImg" src={deleteImg} /> */}
                                        </button>
                                    </GuardedComponent>
                                </div>
                            </div>
                        ),
                        children: [
                            /*  {
                                title: ( */
                            <div className="me-3">
                                <GuardedComponent permission={PermissionName.ViewObservation}>
                                    <ObservationListComponent
                                        handleCancel={() => { loadImages(); }}
                                        handleDelete={async (img: ImgFile) => {
                                            const isdeleted: boolean = await deleteFile(img);
                                            if (isdeleted) {
                                                setPhoto([]);
                                                await loadImages().then(() => {
                                                    if (result.doors.length > 0) {
                                                        result.doors.forEach((w) => {
                                                            if (w.observations.length > 0) {
                                                                w.observations.forEach((o) => {
                                                                    console.log(o);

                                                                    if (o.photos.length > 0) {
                                                                        let photo = o.photos.find(
                                                                            (p) =>
                                                                                p.fileId ===
                                                                                parseInt(
                                                                                    img.fileID,
                                                                                ),
                                                                        );
                                                                        if (photo) {
                                                                            let filteredPhotos =
                                                                                o.photos.filter(
                                                                                    (p) =>
                                                                                        p.fileId !==
                                                                                        parseInt(
                                                                                            img.fileID,
                                                                                        ),
                                                                                );
                                                                            filteredPhotos.length >
                                                                                0
                                                                                ? (o.photos = [
                                                                                    ...filteredPhotos,
                                                                                ])
                                                                                : (o.photos = []);
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        }}
                                        handleSave={async (file: ImgFile) => {
                                            const isedited = await editComments({
                                                fileId: file.fileID!,
                                                comments: file.comment,
                                                isBaseImg: file.isbaseImg,
                                                baseID: file.baseID,
                                                entityID: file.entityID,
                                                entityType: file.entityType,
                                                fileID: file.fileID,
                                                userGuid: '',
                                            });
                                            if (isedited) {
                                                setPhoto([]);
                                                await loadImages().then(() => {
                                                    if (result.doors.length > 0) {
                                                        result.doors.forEach((w) => {
                                                            if (w.observations.length > 0) {
                                                                w.observations.forEach((o) => {
                                                                    console.log(o);

                                                                    if (o.photos.length > 0) {
                                                                        let photo = o.photos.find(
                                                                            (p) =>
                                                                                p.fileId ===
                                                                                parseInt(
                                                                                    file.fileID,
                                                                                ),
                                                                        );
                                                                        if (photo) {
                                                                            o.photos.find(
                                                                                (p) =>
                                                                                    p.fileId ===
                                                                                    parseInt(
                                                                                        file.fileID,
                                                                                    ),
                                                                            ).comments =
                                                                                file.comment;
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        }}
                                        addphoto={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            showImgPreview({
                                                imgpreview: true,
                                                src: e.target.files[0],
                                            });
                                            observationtype.current = {
                                                entityID: item.doorID,
                                                entityType: ObservationType.Door,
                                            };
                                        }}
                                        onAddNewObservationClick={() =>
                                            onAddObservationClick(item, false)
                                        }
                                        oncopyobservation={() => onAddObservationClick(item, true)}
                                        onShowObservationPhotos={onShowObservationPhotos}
                                        photos={photos}
                                        observations={item?.observations}
                                        onEditObservation={onEditObservationClick}
                                        onDeleteObservation={onDeleteObservationClick}
                                        onOpenGallery={openGallery}
                                        imgList={imgList}
                                    />

                                    {imgpreview.imgpreview ? (
                                        <ImageUploader
                                            onAddImg={(comment: string) => {
                                                imgpreview.comment = comment;
                                                handleAddFileToImgList(imgpreview.src);
                                            }}
                                            onclose={() => {
                                                showImgPreview({
                                                    imgpreview: false,
                                                    src: null,
                                                });
                                            }}
                                            showModal={imgpreview.imgpreview}
                                            img={imgpreview.src}
                                        />
                                    ) : null}
                                </GuardedComponent>
                            </div>,
                            /*   ),
                            }, */
                        ],
                    };
                },
            );
            return panelItems;
        }
    };

    if (isError) {
        return <div>Error loading doors</div>;
    }

    return (
        <div className="mt-2">
            <GuardedComponent permission={PermissionName.AddDoor}>
                <div className='stack_title1 mb-2 d-flex justify-content-end'>
                    <button className="ms-auto btn btn-outline-primary btn-sm" onClick={onAddDoor}>
                        Add Door
                    </button>
                </div>
            </GuardedComponent>

            <LoadingIndicator isLoading={isLoading} />
            {roomData && (
                <PanelBar
                    expandMode="single"
                    onSelect={(e) => {
                        console.log(e.target.key);
                        let doorID: string =
                            e.target.key.split('$')[e.target.key.split('$').length - 1];
                        console.log(doorID);

                        observationtype.current = {
                            entityID: parseInt(doorID),
                            entityType: ObservationType.Door,
                        };

                        setImgList([]);

                        setPhoto([]);
                        loadImages();
                    }}
                >
                    {' '}
                    {doorData().map((e) => {
                        return (
                            <PanelBarItem key={e.id} title={e.title}>
                                {e.children}
                            </PanelBarItem>
                        );
                    })}
                </PanelBar>
            )}
            {imageListData && <ImageListModelDialog data={imageListData} onCancel={closGallery} />}
            {data && <EditDoor data={{ building: building, editDoor: data.editDoor }} onClose={handleSaveDoorComplete} />}
            {newObservation && (
                <EditObservation
                    observationType={ObservationType.Door}
                    building={building}
                    data={newObservation}
                    onClose={handleSaveObservationComplete}
                    imgList={imgList}
                    uploadedFiles={uploadedfileList.current}
                />
            )}
            {deleteDoorData && (
                <DeleteModelDialog
                    title="Delete Door"
                    deleteData={deleteDoorData}
                    onClose={handleDeleteDoorComplete}
                >
                    <div>
                        Are you sure you want to delete Door - {deleteDoorData.data.doorName}?
                    </div>
                </DeleteModelDialog>
            )}
            {deleteObservation && (
                <DeleteModelDialog
                    title="Delete Observation"
                    deleteData={deleteObservation}
                    onClose={handleDeleteObservationComplete}
                >
                    <div>
                        Are you sure you want to delete Observation -{' '}
                        {deleteObservation.data.observation}?
                    </div>
                </DeleteModelDialog>
            )}
            <LinkImageModelDialog
                buildingId={building.buildingID}
                linkImageData={linkImageData}
                title={'Link Window'}
                onClose={handleLinkWinodwComplete}
            />
        </div>
    );
};
