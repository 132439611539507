import { createContext, ReactNode, useReducer } from 'react';
import { AppContextData } from '../models/app-context-data';
import { User } from '../models/user-security/user';

enum ActionType {
    UpdateUser = 'UpdateUser',
}
type DispatchAction = {
    type: ActionType;
    data: User;
};
type Dispatch = (action: DispatchAction) => void;
type AppContextProviderProps = { children: ReactNode };

function appContextReducer(state: AppContextData, action: DispatchAction) {
    switch (action.type) {
        case ActionType.UpdateUser: {
            return {
                ...state,
                currentUser: action.data,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function AppContextProvider({ children }: AppContextProviderProps) {
    const appState: AppContextData = {
        currentUser: {
            isAuthenticated: false,
            fullName: '',
            userName: '',
            emailAddress: '',
            phone: '',
            userGUID: '',
            userRoles: [],
            currentPermissions: [],
        },
    };
    const [state, dispatch] = useReducer(appContextReducer, appState);
    const value = { state, dispatch };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContext = createContext<{ state: AppContextData; dispatch: Dispatch }>(undefined);

export { AppContext, AppContextProvider, ActionType };
