import * as Yup from 'yup';
import { InputField } from '../../components/form/input.feild.component';
import { FormikModelDialog, SubmitStatus } from '../../components/formik-model-dialog.component';
import { Building } from '../../models/claim/building';
import { EditDialogHeader } from './edit-dialog-header.component';

interface EditBuildingProps {
    data?: Building;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditBuilding = ({ data, onClose }: EditBuildingProps) => {
    const title = data.buildingID === 0 ? 'Add Building' : 'Edit Building';
    const validationSchema = Yup.object().shape({
        buildingName: Yup.string().required('Required'),
        buildingNumber: Yup.string().required('Required'),
        buildingDetails: Yup.string().required('Required'),
    });

    const initialData = {
        claimGuid: data.claimGuid,
        buildingID: data.buildingID,
        buildingName: data.buildingName,
        buildingNumber: data.buildingNumber,
        buildingDetails: data.buildingDetails,
    };

    return (
        <FormikModelDialog width='40%'
            title={<EditDialogHeader title={title} />}
            initialData={initialData}
            validationSchema={validationSchema}
            show={!!data}
            onClose={onClose}
            postRoute="building/building"
        >
            <div className='labelclass'>
                <InputField label="Building Name" name="buildingName" />
                <InputField label="Building Number" name="buildingNumber" />
                <InputField label="Building Details" name="buildingDetails" />
            </div>
        </FormikModelDialog>
    );
};
