import { useState } from 'react';
import * as Yup from 'yup';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../../components/delete-model-dialog.component';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Building } from '../../../models/claim/building';
import { Room } from '../../../models/claim/room';
import { EditDialogHeader } from '../edit-dialog-header.component';
import { EditRoomFrom } from './edit-room.form';

interface EditRoomProps {
    data?: { building: Building; room: Room };
    onClose?: (status: SubmitStatus, data: any) => any;
    onDeleted?: (room: Room) => any;
    showAddAnother?: boolean;
}

export const EditRoom = ({
    data: { building, room },
    onClose,
    onDeleted,
    showAddAnother,
}: EditRoomProps) => {
    const [deleteRoomData, setDeleteRoom] = useState<DeletePostDataInfo>();
    const { isLoading, result } = useLoadData<Building>(
        `building?buildingID=${building.buildingID}`,
    );

    const onDeleteRoomClick = () => {
        const { roomID } = room;
        setDeleteRoom({
            data: { ...room },
            route: `rooms/delete/${roomID}`,
        });
    };

    const onDeleteComplete = (status: DeleteStatus, room: Room) => {
        if (status == DeleteStatus.Completed && onDeleted) {
            onDeleted(room);
        }
        setDeleteRoom(null);
    };

    const postDataFormatter = (data: any): Room => {
        const room: Room = {
            ...data,
            // floorID: parseInt(data.floorID),
            unitID: parseInt(data.unitID),
            UserID: 1,
        };
        return room;
    };
    const title = room?.roomID > 0 ? 'Edit Room' : 'Add Room';

    const validationSchema = Yup.object().shape({
        floorID: Yup.number().required('Required').moreThan(0, `Required`),
        unitID: Yup.number().required('Required').moreThan(0, `Required`),
        roomName: Yup.string().required('Required'),
        roomNumber: Yup.string().required('Required'),
    });

    const initalData: Room = {
        floorID: room.floorID,
        unitID: room.unitID,
        roomID: room.roomID,
        roomName: room.roomName ?? '',
        roomNumber: room.roomNumber ?? '',
    };

    if (deleteRoomData) {
        return (
            <DeleteModelDialog
                title="Delete Unit"
                deleteData={deleteRoomData}
                onClose={onDeleteComplete}
            >
                <div>Are you sure you want to delete room - {deleteRoomData.data.roomName}?</div>
            </DeleteModelDialog>
        );
    }

    return (
        <>
            <FormikModelDialog
                width='40%'
                title={<EditDialogHeader title={title} buildingName={building.buildingName} />}
                customButtons={
                    room.roomID > 0 && (
                        <button className="btn btn-secondary" onClick={onDeleteRoomClick}>
                            Delete
                        </button>
                    )
                }
                initialData={initalData}
                validationSchema={validationSchema}
                show={!!room}
                onClose={onClose}
                postRoute="rooms"
                postDataFormatter={postDataFormatter}
                showAddAnother={showAddAnother}
            >
                <>
                    <LoadingIndicator isLoading={isLoading} />
                    {result && <EditRoomFrom building={result} />}
                </>
            </FormikModelDialog>
        </>
    );
};
