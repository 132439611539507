import { useState } from 'react';
import { Floor } from '../../../models/claim/floor';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';
import { Building } from '../../../models/claim/building';
import { EditFloor } from './edit-floor.component';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { PermissionName } from '../../../models/user-security/permission.name';
import { GuardedComponent } from '../../../components/guarded.componemt';
import iconEdit from '../../../images/edit';
import { Unit } from '../../../models/claim/unit';
import EditIcon from '../../../images/edit';

interface FloorComponentProps {
    building: Building;
    selectFloor: any;
    onEditFloor: any;
    onfloorSelect: Function;
    onFloorDeleted: (floor: Floor) => any;
}

export const FloorComponent = ({
    building,
    selectFloor,
    onEditFloor,
    onfloorSelect,
    onFloorDeleted,
}: FloorComponentProps) => {
    const [editFloor, setEditFloorData] = useState<{ currentBuilding: Building; floor: Floor }>();
    const [searchKey, setSearchKey] = useState<string>();

    const floors = building?.floors.filter((f) => {
        return searchKey ? f.floorName.toLowerCase().search(searchKey.toLowerCase()) > -1 : true;
    });

    const onAddFloor = () => {
        const floor: Floor = {
            floorID: 0,
            buildingID: building.buildingID,
        };
        setEditFloorData({
            currentBuilding: building,
            floor: floor,
        });
    };

    const onEditFloorClick = (floor: Floor) => {
        setEditFloorData({
            currentBuilding: building,
            floor: floor,
        });
    };

    const onItemClick = (event: ListBoxItemClickEvent) => {
        selectFloor(event.dataItem);
        onfloorSelect(event.dataItem)
        console.log(floors);

    };

    const onSearch = (e: any) => {
        setSearchKey(e.target.value);
    };

    const onSaveComplete = (status: SubmitStatus, data: Floor) => {
        if (status == SubmitStatus.Completed) {
            onEditFloor({ ...data });
        }
        setEditFloorData(null);
    };

    const handleFloorDeleted = (floor: Floor) => {
        setEditFloorData(null);
        onFloorDeleted(floor);
    };

    const templatedItem = (props: any) => {
        let { dataItem, ...others } = props;
        let roomCount: number = 0;
        props.dataItem.Units?.forEach((u: Unit) => {
            roomCount = roomCount + u.rooms.length;
        });

        return (
            <li {...others}>
                <div className='d-flex w-100'>
                    <div>
                        <span className='fontMedium'>
                            {props.dataItem.floorName} ({props.dataItem.floorNumber})
                        </span>
                        <div >
                            Units: {props.dataItem.units.length}   Rooms: {roomCount}
                        </div>
                    </div>
                    <GuardedComponent permission={PermissionName.AddFloor}>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditFloorClick(props.dataItem);
                            }}
                            className="action-btns k-grid-edit-command ms-auto"
                        >
                            <EditIcon></EditIcon> {/* <img src={iconEdit} title="Edit" /> */}
                        </button>
                    </GuardedComponent>
                </div>
            </li>
        );
    };

    return (
        <div>
            <div className="d-flex mb-2">
                <h5>FLOOR</h5>

                <GuardedComponent permission={PermissionName.AddFloor}>
                    <button onClick={onAddFloor} className="add-edit-btn ms-auto">
                        Add Floor
                    </button>
                </GuardedComponent>
            </div>

            <div>
                <input
                    type="text"
                    className="form-control mb-2 searchBg gray-bg"
                    placeholder="Search"
                    value={searchKey}
                    onChange={onSearch}
                />
            </div>
            {building?.floors && (
                <ListBox

                    data={floors}
                    item={templatedItem}
                    textField="floorName"
                    selectedField="isSelected"
                    onItemClick={(e: ListBoxItemClickEvent) => onItemClick(e)}
                />
            )}
            {editFloor && (
                <EditFloor
                    data={editFloor}
                    onClose={onSaveComplete}
                    onDeleted={handleFloorDeleted}
                />
            )}
        </div>

    );
};
